/* global $ */
import LayoutVariables from '../../../javascript/src/config/layout_variables'

export default class ProfessionQuizStepWidget {
  constructor() {
    // eslint-disable-next-line radix
    this.currentPage = parseInt($('#current-step').text())
    this.resizeWindow = this.resizeWindow.bind(this)

    this.progressDisplay()
    this.resizeWindow()

    $(window).on('resize', this.resizeWindow)
  }

  progressDisplay() {
    const self = this
    $('.progress--steps').css('background-color', function bgCalc() {
      const dataIndex = Number(this.getAttribute('data-index'))
      return dataIndex < self.currentPage ? '#FC6012' : 'transparent'
    })
  }

  resizeWindow() {
    const viewportWidth = $(window).width()
    const height = $(window).innerHeight()
    let navBarSize

    if (viewportWidth < LayoutVariables.tabletWidth) {
      navBarSize = 45
    } else if (viewportWidth < LayoutVariables.desktopWidth) {
      navBarSize = 43
    } else {
      navBarSize = 60
    }

    $('.profession-quiz-step').each(function eachCssCalc() {
      $(this).css('height', `calc(${height}px - ${navBarSize}px)`)
    })
  }
}
