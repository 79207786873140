import Page from '../../page'

import SuggestedJobsWidget from '../../../../../widgets/suggested_jobs/script'

export default class ProfessionQuizResults extends Page {
  constructor() {
    super()

    new SuggestedJobsWidget()
  }
}
