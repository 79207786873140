/* global $ window document */
import LayoutVariables from '../../javascript/src/config/layout_variables'

import JobPostingBookmarkWidget from '../job_posting/bookmark/script'
import SearchRadiusImprovements from '../search_improvement_suggestions/script'
import AjaxSpinnerWidget from '../ajax_spinner/script'
import JobletterButton from '../jobletter_button/script'
import AbPubSub from '../../javascript/src/lib/ab_pubsub'
import Events from '../../javascript/src/config/events'
import FilterBoxWidget from '../form/filter_box/script'

import UtilityHelper from '../../javascript/src/lib/utility_helper'
import TextEllipsis from '../../javascript/src/lib/text_ellipsis'

export default class MainSearchWithResultsWidget {
  get suggestionTextSelector() {
    return '.similar-professions__suggestions--suggestionstext'
  }

  get rangeTextSelector() {
    return '.similar-professions__suggestions--rangetext'
  }

  get rangeSelector() {
    return 'input[type=range]'
  }

  get searchNamespace() {
    return '.js-main-search-with-results'
  }

  get eventElement() {
    return '.js-trigger-on-replace-history'
  }

  get formSelector() {
    return '.js-main-search-form'
  }

  get resetFormSelector() {
    return '.js-filterbox-reset'
  }

  get jobletterButtonTargetSelector() {
    return '.js-jobletter-button-here'
  }

  get searchTargetSelector() {
    return '.js-job-postings-here'
  }

  get longitudeSelector() {
    return '.js-search-rlon'
  }

  get latitudeSelector() {
    return '.js-search-rlat'
  }

  get locationPermissionSelector() {
    return '.js-open-location-permission-modal'
  }

  get loadMoreButtonSelector() {
    return '.js-load-more-button-here'
  }

  get containerSelector() {
    return 'job-postings-container'
  }

  get titleTargetSelector() {
    return '.js-search-result-title-here'
  }

  get sortOrderSelector() {
    return '.js-sort-order-dropdown'
  }

  get sortOrderHiddenField() {
    return '.js-search-sort-order'
  }

  get whereSelector() {
    return '#main-search-where'
  }

  get bookmarkSelectors() {
    return '.js-bookmark-job-posting'
  }

  constructor(loadResultsOnScroll, batchSize, showSuggestions = true) {
    this.searchUrl = '/ajax/main_search/'
    this.baseName = 'form_main_search'
    this.$namespace = $(this.searchNamespace)
    this.$window = $(window)
    this.triggerOnScroll = false
    this.scrollThreshold = this.getLoadMoreThreshold()
    this.scrollEventRegistered = false
    this.ajaxSpinner = new AjaxSpinnerWidget()
    this.pubSub = AbPubSub.getInstance()
    this.$jobletterButtonTarget = $(this.jobletterButtonTargetSelector)
    this.$jobletterButtonElemDisabled = $("button[name='subscribe_to_jobletter_disabled']")
    this.timeout = null
    this.loadResultsOnScroll = loadResultsOnScroll
    this.batchSize = batchSize
    this.showSuggestions = showSuggestions

    this.filterBox = new FilterBoxWidget()
    this.subscribeFilterBoxChange()
    this.updateHiddenFieldsFromFilterBox()
    this.addResetHandler()

    this.freshlyLoaded = true
    this.numberJobPostingsOnPageBeforeRadiusChange = 0
    this.vacanciesCount = 0
    this.jobPostingClusterCount = 0
    this.vacanciesCountOnRadiusChange = 0
    this.$target = $(this.searchTargetSelector)
    this.$titleTarget = $(this.titleTargetSelector)
    this.jobPostingsContainer = document.getElementById(this.containerSelector)
    this.$locationPermission = $(this.locationPermissionSelector)
    this.form = this.formSelector
    this.handleFormSubmit()
    this.formName = this.getForm().attr('id').substring(4)

    this.afterInit()
    this.sortOrderSelect = $(this.sortOrderSelector).selectize({ sortField: 'text' })
    this.initSortOrderSelectize()

    this.initialNextDataLayerObject = undefined
    this.lastDataLayerObject = undefined
    this.clickBreakerTile()
  }

  afterInit() {
    this.initSearchForm()
    this.jobPostingBookmarkWidget = new JobPostingBookmarkWidget()

    if (this.searchWithGeolocation()) {
      navigator.geolocation.getCurrentPosition(
        (coordinates) => {
          this.setCoordinates(coordinates)
          this.triggerFirstSearch()
        },
        () => {
          this.triggerFirstSearch()
        }
      )
    } else {
      this.triggerFirstSearch()
    }
  }

  triggerFirstSearchBefore() {
    this.$jobletterButtonTarget.empty()
    this.$jobletterButtonElemDisabled.show()

    this.updateRangeSlider()

    $(this.eventElement).trigger('ab.onJsReplaceUrl', { url: this.getSerializedFormData() })

    // always unregisterScrollLoading
    // register it again if necessary
    this.unregisterScrollLoading()
  }

  updateRangeSlider() {
    const whereValue = $(this.whereSelector).val()
    if ($(this.whereSelector).attr('placeholder') === 'Mein Standort' || (whereValue && whereValue.trim() !== '')) {
      this.filterBox.enableRangeSlider()
    } else {
      this.filterBox.disableRangeSlider()
    }
  }

  resetParamsToDefault() {
    const defaultValues = {
      expected_graduation: '',
      industry_public_id: '',
      min_radius: '0',
      profession_public_id: '',
      profession_topic_public_id: '[]',
      radius: '20',
      show_educational_trainings_and_regular_apprenticeships: '1',
      show_educational_trainings: '1',
      show_inhouse_trainings: '1',
      show_integrated_degree_programs: '1',
      show_qualifications: '1',
      show_regular_apprenticeships: '1',
      show_training_programs: '1',
      sort_order: 'relevance',
      starts_no_earlier_than: '',
      video_application_on: '',
      what: '',
      where: '',
      promote_foreign_applications: false,
    }

    Object.keys(defaultValues).forEach((fieldName) => {
      this.filterBox.resetFilterValue(fieldName)
      this.updateHiddenFieldFilterValue(this.getFieldNameSelector(fieldName), defaultValues[fieldName])
    })

    const checkboxes = document.querySelectorAll('.filter-box__apprenticeship-types input[type=checkbox]')
    checkboxes.forEach((checkboxElement) => {
      const checkbox = checkboxElement
      checkbox.checked = true
    })
  }

  addResetHandler() {
    $(this.resetFormSelector).on('click', () => this.resetParamsToDefault())
  }

  firstSearchDoneBefore() {
    this.breakerTiles = this.pageobj.filter('.js-breaker-tile-records').children()
    this.searchImprovementSuggestions = this.pageobj.filter('.js-search-improvement-suggestions').children()
    this.jobletterButton = this.pageobj.filter('.js-jobletter-button').children()
  }

  firstSearchDoneAfter() {
    if (this.loadResultsOnScroll) {
      this.triggerOnScroll = true

      // if its the very first call and its not the last page / no more results
      // register event listener for scroll loading
      if (!this.scrollEventRegistered && this.hasChildren(this.loadMoreButton)) {
        this.registerScrollLoading()
        this.scrollEventRegistered = true
      }
    } else if (this.hasChildren(this.loadMoreButton)) {
      $('.js-load-more-button-here').append(this.loadMoreButton)
      $('.js-load-more-search-results').on('click', () => {
        this.triggerFurtherSearch(this.from(), this.batchSize)
      })
    }

    $('.js-breaker-tile-here').empty().append(this.breakerTiles)
    $('.js-number-two-flags-safe-haven').empty().append(this.announcements)

    this.improveSearchSuggestions()

    this.$jobletterButtonTarget.empty().append(this.jobletterButton) // show jobletter button
    this.$jobletterButtonElemDisabled.hide() // hide disabled button
    this.$jobletterButton = new JobletterButton()
    $('.breaker-tiles__show-more').on('click', () => {
      $('.more-results').toggleClass('more-results--hidden')
      $('.breaker-tiles__show-more').toggleClass('open')
    })

    this.onSearchDone()

    if (this.$rangeSlider.val() === '1000') {
      this.$rangeText = $(this.rangeTextSelector)
      this.$suggestionText = $(this.suggestionTextSelector)

      this.$rangeText.css({ display: 'none' })
    }

    this.pushDataLayer()
  }

  pushDataLayer() {
    let breakerData = {}

    const breakerTileExists = this.breakerTiles.length > 0
    if (breakerTileExists) {
      const firstTile = this.breakerTiles[0]
      const isProfession = firstTile.classList.contains('tile--profession')
      const isPaidProfession = this.breakerTiles
        .find('.tile__paid-job-info')
        .text()
        .slice('Ein Beruf von '.length)
        .trim()

      let corpName
      if (!isProfession) {
        corpName = this.breakerTiles.find('.tile__title').text().trim()
      } else if (isProfession && isPaidProfession !== '') {
        corpName = isPaidProfession
      } else {
        corpName = 'ABNotDefined'
      }

      breakerData = {
        breaker: isProfession ? 'berufsprofilbreaker' : 'unternehmensprofilbreaker',
        prof: isProfession ? this.breakerTiles.find('.tile__title').text().trim() : 'ABNotDefined',
        corpName,
        corpID: !isProfession ? firstTile.id : 'ABNotDefined',
      }
    } else {
      breakerData = {
        breaker: 'ABNotDefined',
        prof: 'ABNotDefined',
        corpName: 'ABNotDefined',
        corpID: 'ABNotDefined',
      }
    }

    const finalDataLayerObject = {
      countVac: this.vacanciesCount,
      countJPC: this.jobPostingClusterCount,
      ...breakerData,
      ...this.initialNextDataLayerObject,
    }

    window.dataLayer.push(finalDataLayerObject)

    this.lastDataLayerObject = finalDataLayerObject
  }

  improveSearchSuggestions() {
    if (this.showSuggestions) {
      $('.js-search-improvement-suggestions-here').empty().append(this.searchImprovementSuggestions)
      this.searchRadiusImprovements = new SearchRadiusImprovements()
    }
  }

  subscribeRadiusChange() {
    this.pubSub.subscribe(Events.ON_SEARCH_RADIUS_CHANGE, (data) => {
      this.vacanciesCountOnRadiusChange = this.vacanciesCount
      this.numberJobPostingsOnPageBeforeRadiusChange = this.currentNumberOfJobPostingsOnPage()
      this.updateHiddenFieldFilterValue(this.getFieldNameSelector('min_radius'), this.$rangeSlider.val())
      this.$rangeSlider.val(data.radius).trigger('change')
      this.triggerFurtherSearch(0, this.batchSize - (this.numberJobPostingsOnPageBeforeRadiusChange % this.batchSize))
    })
  }

  triggerFurtherSearchBefore() {
    this.unregisterScrollLoading()
  }

  furtherSearchDoneBefore() {
    this.searchImprovementSuggestions = this.pageobj.filter('.js-search-improvement-suggestions').children()
  }

  furtherSearchDoneAfter() {
    if (this.loadResultsOnScroll) {
      this.triggerOnScroll = true
      // case: within first three searches result is empty
      if (!this.hasChildren(this.loadMoreButton)) {
        this.unregisterScrollLoading()
      } else if (this.currentPage() % 3 === 0 && this.hasChildren(this.loadMoreButton)) {
        // case: three or more search queries were sent and result is not empty
        $('.js-load-more-button-here').append(this.loadMoreButton)
        $('.js-load-more-search-results').on('click', () => {
          this.triggerFurtherSearch(this.from(), this.batchSize)
        })
        this.unregisterScrollLoading()
      } else {
        this.registerScrollLoading()
      }
    } else if (this.hasChildren(this.loadMoreButton)) {
      $('.js-load-more-button-here').append(this.loadMoreButton)
      $('.js-load-more-search-results').on('click', () => {
        this.triggerFurtherSearch(this.from(), this.batchSize)
      })
    }

    this.improveSearchSuggestions()

    this.onSearchDone()
  }

  registerScrollLoading() {
    this.$window.on('scroll', this.onScroll.bind(this))
  }

  unregisterScrollLoading() {
    this.$window.off('scroll')
    this.scrollEventRegistered = false
  }

  hasChildren(element) {
    return element.children().length > 0
  }

  onScroll() {
    if (this.currentPage() % 3 !== 0) {
      if (this.isInViewport() && this.triggerOnScroll) {
        this.triggerOnScroll = false
        this.triggerFurtherSearch(this.from(), this.batchSize)
      }
    }
  }

  from() {
    return this.currentNumberOfJobPostingsOnPage() - this.numberJobPostingsOnPageBeforeRadiusChange
  }

  currentPage() {
    return this.currentNumberOfJobPostingsOnPage() / this.batchSize
  }

  currentNumberOfJobPostingsOnPage() {
    return document.querySelectorAll('.js-job-posting-card').length
  }

  getFieldNameSelector(paramName) {
    return `[name="${this.baseName}[${paramName}]"]`
  }

  updateHiddenFieldFilterValue(inputFieldSelector, value) {
    $(inputFieldSelector).val(value)
  }

  subscribeFilterBoxChange() {
    this.pubSub.subscribe(Events.ON_CHANGE_FILTER_BOX, (value) => {
      this.updateHiddenFieldFilterValue(this.getFieldNameSelector(value.name), value.value)
    })
  }

  isInViewport() {
    return this.getWindowOffset() > $('.js-load-more-button-here').offset().top
  }

  getWindowOffset() {
    return this.$window.scrollTop() + this.$window.height()
  }

  getThreshold() {
    return $(document).height() - this.scrollThreshold
  }

  getLoadMoreThreshold() {
    const width = this.$window.width()
    let threshold = 580

    if (width < LayoutVariables.tabletWidth) {
      threshold = 980
    } else if (width >= LayoutVariables.tabletWidth && width < LayoutVariables.desktopWidth) {
      threshold = 720
    }

    return threshold
  }

  initSearchForm() {
    const loadCallback = this.triggerFirstSearch.bind(this)

    if (this.filterBox !== undefined) {
      this.filterBox.initFilterBox(loadCallback)
      this.subscribeRadiusChange()
    }

    this.$rangeSlider = $(this.rangeSelector)
    this.$rangeOutput = $(this.rangeOutputSelector)

    $('.js-notify-on-change').on('change', loadCallback)
    $('.js-reset-subscribe-button').on('change', () => {
      this.pubSub.emit(Events.ON_SEARCH_FORM_INPUT_CHANGE)
    })
  }

  // from common lib

  handleFormSubmit() {
    this.getForm().on('submit', (event) => {
      event.preventDefault()
      this.triggerFirstSearch()
    })
  }

  getForm() {
    return $(this.form)
  }

  triggerFirstSearch() {
    if (this.timeout !== null) {
      clearTimeout(this.timeout)
    }

    // We use the min_radius to prevent duplication when loading additional postings
    // When this value persists when reloading the page or redoing the search,
    // some job postings are exlucded
    // So reset this value always to zero
    $('#form_main_search_min_radius').attr('value', 0)
    this.numberJobPostingsOnPageBeforeRadiusChange = 0
    this.vacanciesCountOnRadiusChange = 0

    this.$target.empty()
    this.$titleTarget.empty()
    $(this.loadMoreButtonSelector).empty()
    this.ajaxSpinner.showSpinner()

    this.timeout = setTimeout(() => {
      this.triggerFirstSearchBefore()

      const doneCallback = this.firstSearchDone.bind(this)
      const failCallback = this.firstSearchFail.bind(this)
      const self = this
      const appendParams = !this.freshlyLoaded

      if (this.freshlyLoaded) {
        this.freshlyLoaded = false
      }

      const data = this.getSearchParams(0, this.batchSize)
      this.initialNextDataLayerObject = this.getInitialNextDataLayerObject()

      $.ajax({
        url: this.getSearchUrl(),
        data,
      })
        .done((result) => {
          doneCallback(result)
          if (appendParams) self.updateState()
        })
        .fail(failCallback)
    }, 250)
  }

  getInitialNextDataLayerObject() {
    const formData = {}
    this.getForm()
      .serializeArray()
      .forEach((field) => {
        formData[field.name] = field.value
      })

    const data = {
      wasFilter: formData['form_main_search[what]'],
      woFilter: this.getWoFilter(),
      radiusFilter: formData['form_main_search[radius]'],
      abschlussFilter: this.filterBox.dataDL.expectedGraduationFilter,
      berufFilter: this.filterBox.dataDL.professionFilter,
      berufThemaFilter: this.filterBox.dataDL.professionTopicsFilter,
      brancheFilter: this.filterBox.dataDL.industryFilter,
      startFilter: formData['form_main_search[starts_no_earlier_than]'],
      ausbildungsartenFilter: this.filterBox.dataDL.apprenticeshipTypes,
      sortierung: formData['form_main_search[sort_order]'],
    }

    const event = this.getDataLayerEventType(data)

    return {
      event,
      ...data,
    }
  }

  getWoFilter() {
    const where = $(this.whereSelector)
    const val = where.val()
    const placeholder = where.attr('placeholder')

    if (val !== '') {
      return val
    }

    if (placeholder === 'Mein Standort') {
      return where.attr('data-location')
    }

    return val
  }

  clickBreakerTile() {
    const handleClick = (event) => {
      const clickedTile = event.target.closest('.tile')
      if (!clickedTile) return

      const isProfession = clickedTile.classList.contains('tile--profession')
      const paidJobInfo = clickedTile.querySelector('.tile__paid-job-info')
      let isPaidProfession = ''
      if (paidJobInfo) {
        isPaidProfession = paidJobInfo.textContent.slice('Ein Beruf von '.length).trim()
      }

      let corpName
      if (!isProfession) {
        corpName = clickedTile.querySelector('.tile__title').textContent.trim()
      } else if (isProfession && isPaidProfession !== '') {
        corpName = isPaidProfession
      } else {
        corpName = 'ABNotDefined'
      }

      const tileData = {
        event: 'search_click',
        breaker: isProfession ? 'berufsprofilbreaker' : 'unternehmensprofilbreaker',
        prof: isProfession
          ? clickedTile.querySelector('.tile__title').textContent.trim() || 'ABnotDefined'
          : 'ABnotDefined',
        corpID: !isProfession ? clickedTile.id || 'ABnotDefined' : 'ABnotDefined',
        corpName,
      }

      window.dataLayer.push(tileData)
    }

    document.addEventListener('click', handleClick)
  }

  getDataLayerEventType(newData) {
    // "search" bei Änderung in Was oder WO
    // und wenn die Suche mit den unveränderten Parametern noch mal abgefeuert wird
    // "search_filter" bei jeder Anpassung der Filtereinstellungen
    if (!this.lastDataLayerObject) {
      return 'search'
    }

    const searchBarFields = [
      'radiusFilter',
      'abschlussFilter',
      'berufFilter',
      'berufThemaFilter',
      'brancheFilter',
      'startFilter',
      'ausbildungsartenFilter',
    ]

    const filtersChanged = searchBarFields.some(
      (searchBarField) => this.lastDataLayerObject[searchBarField] !== newData[searchBarField]
    )

    return filtersChanged ? 'search_filter' : 'search'
  }

  getSerializedFormData() {
    this.updateHiddenFieldsFromFilterBox()
    this.updateHiddenFieldSortOrder()
    return this.getForm().serialize()
  }

  updateHiddenFieldSortOrder() {
    const hiddenField = document.querySelector(this.sortOrderHiddenField)

    if (hiddenField) {
      document.querySelector(this.sortOrderHiddenField).value = this.sortOrder
    }
  }

  updateHiddenFieldsFromFilterBox() {
    if (this.filterBox !== undefined) {
      const filterParams = this.filterBox.getFilterParams()
      filterParams.forEach((params) => {
        this.updateHiddenFieldFilterValue(this.getFieldNameSelector(params.name), params.value)
      })
    }
  }

  addOptionalUrlParam(params, url, key) {
    const paramValue = params.get(key)
    if (paramValue) {
      url.searchParams.set(key, paramValue)
    }
  }

  // A/B testing for search
  // https://ausbildungde.atlassian.net/browse/AB-7714 oa
  // https://ausbildungde.atlassian.net/browse/AB-7715 bf
  getSearchUrl() {
    const url = new URL(`${window.location.origin}${this.searchUrl}`)
    const currentParams = new URLSearchParams(window.location.search)
    const keys = ['jp_template', 'oa', 'bf']
    keys.forEach((key) => this.addOptionalUrlParam(currentParams, url, key))

    return url.toString()
  }

  firstSearchDone(page) {
    this.pageobj = $(page)
    this.firstSearchDoneBefore()

    const activeFilters = this.pageobj.filter('.js-search-filters').children()
    const searchResultTitle = this.pageobj.filter('.js-search-result-title').children()

    this.vacanciesCount = this.pageobj.filter('.js-search-result-title').data('vacanciesCount')
    this.jobPostingClusterCount = this.pageobj.filter('.js-search-result-title').data('resultsCount')

    this.loadMoreButton = this.pageobj.filter('.js-load-more-button').children()
    this.jobPostings = this.pageobj.filter('.js-job-postings').children()
    this.ajaxSpinner.hideSpinner()

    $('.js-active-filters').empty().append(activeFilters)
    this.$target.empty().append(this.jobPostings)
    this.$titleTarget.empty().append(searchResultTitle)

    if (this.filterBox !== undefined) {
      $('.js-filter-button').on('click', (event) => this.buttonRemove(event, this.filterBox))
    }

    const professionPublicId = $('#job-postings-container').data('profession-public-id')
    if (professionPublicId !== '') {
      this.pubSub.emit(Events.ON_MAIN_SEARCH_RESULTS_LOADED, {
        'profession-public-id': professionPublicId,
        jobPostingsLength: this.jobPostings.length,
        searchParams: this.getSearchParams(0, this.batchSize),
      })
    }

    this.firstSearchDoneAfter()
  }

  firstSearchFail() {
    this.ajaxSpinner.hideSpinner()
    this.$target.empty().append('Ups! Das hat nicht geklappt, sorry! Versuch es bitte später noch mal, ok?')
  }

  triggerFurtherSearch(from, size) {
    this.triggerFurtherSearchBefore()
    if (!this.formName) {
      this.formName = this.getForm().attr('id').substring(4)
    }

    const doneCallback = this.furtherSearchDone.bind(this)
    const failCallback = this.furtherSearchFail.bind(this)
    $(this.loadMoreButtonSelector).empty()
    this.ajaxSpinner.showSpinner()
    $.ajax({
      url: this.getSearchUrl(),
      data: this.getSearchParams(from, size),
    })
      .done(doneCallback)
      .fail(failCallback)
  }

  getSearchParams(from, size) {
    const jobsOnPageCount = from + this.numberJobPostingsOnPageBeforeRadiusChange
    return `${this.getSerializedFormData()}&${this.formName}[from]=${from}&${
      this.formName
    }[size]=${size}&${this.formName}[jobs_on_page_count]=${jobsOnPageCount}&${
      this.formName
    }[vacancies_count]=${this.vacanciesCountOnRadiusChange}`
  }

  furtherSearchDone(page) {
    this.pageobj = $(page)
    this.furtherSearchDoneBefore()

    this.jobPostings = this.pageobj.filter('.js-job-postings').children()
    this.loadMoreButton = this.pageobj.filter('.js-load-more-button').children()

    $('.js-load-more-search-results').off().remove()
    this.ajaxSpinner.hideSpinner()

    this.$target.append(this.jobPostings)
    const searchResultTitle = this.pageobj.filter('.js-search-result-title').children()
    this.$titleTarget.empty().append(searchResultTitle)

    this.pubSub.emit(Events.ON_RELOAD_LOGIN_LINKS)

    this.vacanciesCount = this.pageobj.filter('.js-search-result-title').data('vacanciesCount')
    this.jobPostingClusterCount = this.pageobj.filter('.js-search-result-title').data('resultsCount')

    this.furtherSearchDoneAfter()
  }

  furtherSearchFail() {
    this.ajaxSpinner.hideSpinner()
    this.$target.append('Ups! Das hat nicht geklappt, sorry! Versuch es bitte später noch mal, ok?')
  }

  buttonRemove(event, filterBox) {
    const target = event.currentTarget
    const fieldName = target.dataset.formFieldName

    // notify filterbox about the filter change
    filterBox.resetFilterValue(fieldName)
    $(target).remove()

    if ($('.search-filters__selected')[0].children.length === 0) {
      $('.search-filters').remove()
    }
  }

  activateLoadMoreButton() {
    $(this.loadMoreButtonSelector).empty().append(this.loadMoreButton)
    $('.js-load-more-search-results').on('click', () => {
      this.triggerFurtherSearch(this.from(), this.batchSize)
    })
  }

  onSearchDone() {
    this.$namespace.trigger(Events.ON_SEARCH_DONE)
    TextEllipsis.getInstance().create()

    this.pubSub.emit(Events.ON_JOB_POSTINGS_LOADED, {
      parentSelector: '.search-result__wrapper',
      selector: '.js-bookmark-job-posting',
      prefix: '',
      eventData: JSON.stringify(this.initialNextDataLayerObject),
    })
  }

  enableEventDelegation() {
    this.jobPostingsContainer.addEventListener('click', (e) => {
      if (e.target && e.target.classList.contains('js-open_login_modal')) {
        this.triggerPubSubEvent(e, this.pubSub, Events.ON_OPEN_LOGIN_MODAL)
      } else if (e.target.classList.contains('js-open_email_update_modal')) {
        this.triggerPubSubEvent(e, this.pubSub, Events.ON_OPEN_EMAIL_UPDATE_MODAL)
      } else if (e.target.classList.contains('js-open_activation_modal')) {
        this.triggerPubSubEvent(e, this.pubSub, Events.ON_OPEN_ACTIVATION_MODAL)
      }
    })
  }

  updateState() {
    if (this.filterBox === undefined) return

    const params = UtilityHelper.getUrlParams(window.location.href)
    const filters = this.filterBox.getFilterParams()
    const filtersParams = {}

    // Get values form filterbox widget
    filters.push({ name: 'sort_order', value: this.sortOrder })

    filters.forEach((filter) => {
      filtersParams[`form_main_search[${filter.name}]`] = filter.value
    })

    const formParamReducer = (lastValue, currentValue) => {
      const newEntry = { [currentValue.name]: currentValue.value }
      return {
        ...lastValue,
        ...newEntry,
      }
    }

    // merge old params with updated values
    const formParams = this.getForm()
      .serializeArray()
      .filter((param) => ['form_main_search[what]', 'form_main_search[where]'].includes(param.name))
      .reduce(formParamReducer, {})

    const allParams = { ...params, ...filtersParams, ...formParams }
    const filterAndFormParams = { ...filtersParams, ...formParams }
    const newParams = params !== null ? allParams : filterAndFormParams

    // Update tracking params
    newParams.t_where = newParams['form_main_search[where]']
    newParams.t_what = newParams['form_main_search[what]']

    window.history.replaceState(newParams, '', `?${new URLSearchParams(newParams)}`)
  }

  triggerPubSubEvent(event, emitter, abEvent) {
    emitter.emit(abEvent)
    event.preventDefault()
    event.stopPropagation()
  }

  searchWithGeolocation() {
    const locationPermission = this.$locationPermission.length
    const locationSessionPermission = this.$locationPermission.data('session') === 'gave_permission'
    const windowNavigatorLocation = window.navigator && window.navigator.geolocation

    return locationPermission && locationSessionPermission && windowNavigatorLocation
  }

  setCoordinates(coordinates) {
    $(this.longitudeSelector).val(coordinates.coords.longitude)
    $(this.latitudeSelector).val(coordinates.coords.latitude)
  }

  initSortOrderSelectize() {
    // TODO: Hack for disabling cursor after initializing selectize.
    $(`${this.sortOrderSelector} input`).prop('disabled', true)
    const sortOrderSelectize = this.sortOrderSelect[0].selectize
    const [item] = sortOrderSelectize.items
    this.sortOrder = item
    sortOrderSelectize.on('change', (selected) => this.handleSortChange(selected))
  }

  handleSortChange(selected) {
    this.sortOrder = selected

    if (window.innerWidth < 768 && selected === 'start_date') {
      const sortOrderInner = document.querySelector('.js-sort-order-dropdown .item')
      sortOrderInner.innerText = 'Beginn'
    }

    this.triggerFirstSearch()
  }
}
