import Page from '../page'

import MainSearchWithResultsWidget from '../../../../widgets/main_search_with_results/script'
import SocialMediaElementWidget from '../../../../widgets/social_media_element/script'

export default class SeoKeywordPage extends Page {
  constructor() {
    super()
    this.mainSearchWithResultsWidget = new MainSearchWithResultsWidget(false, 24)
    this.socialMediaElementWidget = new SocialMediaElementWidget()
  }
}
