/* global $ */

import TextEllipsis from '../../javascript/src/lib/text_ellipsis'
import JobPostingBookmarkWidget from '../job_posting/bookmark/script'

const LOAD_MORE_BUTTON = '.js-load-more-button'
const SUGGESTED_JOB = '.suggested-job'
const SUGGESTED_JOB_HIDDEN = '.suggested-job:hidden'

export default class SuggestedJobsWidget {
  constructor() {
    // eslint-disable-next-line no-new
    new JobPostingBookmarkWidget()
    this.loadMore()
    TextEllipsis.getInstance().create()
    this.addClickListener()
  }

  loadMore() {
    $(SUGGESTED_JOB).slice(0, 6).show() // select the first six professions
    $(LOAD_MORE_BUTTON).on('click', () => {
      $(SUGGESTED_JOB_HIDDEN).slice(0, 6).show() // select next 6 hidden professions and show them
      if ($(SUGGESTED_JOB_HIDDEN).length === 0) {
        // check if any hidden profession still exist
        $(LOAD_MORE_BUTTON).hide() // Hide button if not exist
      }
    })
  }

  addClickListener() {
    $(SUGGESTED_JOB).each((index, element) => {
      $(element).on('click', () => {
        const professionTitle = $(element).data('profession-title')

        window.dataLayer.push({
          event: 'profession_quiz_result',
          selected_element: professionTitle,
        })
      })
    })
  }
}
