/* global $ */

import JobPostingBookmarkWidget from '../../../../job_posting/bookmark/script'
import AjaxSpinnerWidget from '../../../../ajax_spinner/script'
import Events from '../../../../../javascript/src/config/events'
import AbPubSub from '../../../../../javascript/src/lib/ab_pubsub'
import TextEllipsis from '../../../../../javascript/src/lib/text_ellipsis'

export default class DaExplanationJobPostingsWidget {
  get searchNamespace() {
    return '.js-job-postings-da__search'
  }

  get searchTargetSelector() {
    return '.js-job-postings-here'
  }

  get locationPermissionSelector() {
    return '.js-open-location-permission-modal'
  }

  get longitudeSelector() {
    return '.js-search-rlon'
  }

  get latitudeSelector() {
    return '.js-search-rlat'
  }

  get loadMoreButtonTargetSelector() {
    return '.js-load-more-button-here'
  }

  get containerSelector() {
    return '#job-postings-container'
  }

  get titleTargetSelector() {
    return '.js-search-result-title-here'
  }

  constructor() {
    const ns = this.searchNamespace
    this.$namespace = $(ns)

    this.init()
  }

  init() {
    this.searchUrl = $('.js-job-postings-da__search-filter').attr('data-search-url')
    this.jobPostingBookmarkWidget = new JobPostingBookmarkWidget()
    this.ajaxSpinner = new AjaxSpinnerWidget()
    this.currentPage = 0
    this.$target = $(this.searchTargetSelector)
    this.$titleTarget = $(this.titleTargetSelector)
    this.$jobPostingsContainer = $(this.containerSelector)
    this.$locationPermission = $(this.locationPermissionSelector)
    this.pubSub = AbPubSub.getInstance()

    this.afterInit()
  }

  async afterInit() {
    if (this.searchWithGeolocation()) {
      const coordinates = await this.fetchCoordinates()
      if (coordinates) {
        this.setCoordinates(coordinates)
      }
    }

    this.triggerSearch()
  }

  fetchCoordinates() {
    return new Promise(resolve => {
      navigator.geolocation.getCurrentPosition(resolve, () => {
        resolve(null)
      })
    })
  }

  searchWithGeolocation() {
    return (
      this.$locationPermission.length
      && this.$locationPermission.data('session') === 'gave_permission'
      && window.navigator
      && window.navigator.geolocation
    )
  }

  setCoordinates(coordinates) {
    $(this.longitudeSelector).val(coordinates.coords.longitude)
    $(this.latitudeSelector).val(coordinates.coords.latitude)
  }

  triggerSearch() {
    this.searchBefore()
    const doneCallback = this.searchDone.bind(this)

    $.ajax({
      url: this.searchUrl,
      data: { currentPage: this.currentPage }
    }).done(doneCallback)
  }

  searchBefore() {
    this.currentPage += 1
    $(this.loadMoreButtonTargetSelector).empty()
    this.ajaxSpinner.showSpinner()
  }

  searchDone(page) {
    this.pageobj = $(page)
    this.loadMoreButton = this.pageobj.filter('.js-load-more-button').children()
    this.jobPostings = this.pageobj.filter('.js-job-postings').children()
    this.ajaxSpinner.hideSpinner()

    this.$target.append(this.jobPostings)
    this.activateLoadMoreButton()
    this.onSearchDone()
  }

  onSearchDone() {
    this.$namespace.trigger(Events.ON_SEARCH_DONE)
    this.pubSub.emit(Events.ON_RELOAD_LOGIN_LINKS)
    TextEllipsis.getInstance().create()
  }

  activateLoadMoreButton() {
    $('.js-load-more-search-results').off().remove()
    $(this.loadMoreButtonTargetSelector).empty().append(this.loadMoreButton)
    $('.js-load-more-search-results').on('click', this.triggerSearch.bind(this))
  }
}
