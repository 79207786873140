import PasswordFieldWithValidationWidget from '../../../../widgets/password_field_with_validation/script'
import Page from '../page'

const PWF_SELECTOR = '.password-reset'

export default class PasswordResetPage extends Page {
  constructor() {
    super()

    this.pwf = new PasswordFieldWithValidationWidget(PWF_SELECTOR)
  }
}
