import Page from '../../page'

import SingleSelect from '../../../../../widgets/form/single_select/script'
import ProfessionQuizLastStep from '../../../../../widgets/profession_quiz/last_step/script'
import MultiSelectMultiline from '../../../../../widgets/form/multiple_select_multiline/script'

export default class ProfessionQuizLastPage extends Page {
  constructor() {
    super()

    new SingleSelect()
    new ProfessionQuizLastStep()
    new MultiSelectMultiline()
  }
}
