const CONTAINER_HEIGHT = 333
const CLASS_NAME = 'js-hidden-content'

export default class ShowMore {
  constructor(vph) {
    this.vph = vph

    this.provideSelectors()

    if (this.content) {
      this.sizeContainer()
      this.initBehavior()
    }
  }

  provideSelectors() {
    this.content = document.querySelector('.js-show-more__content')
    this.container = document.querySelector('.js-show-more__container')
    this.controls = document.querySelector('.js-show-more__controls')
    this.gradient = document.querySelector('.js-show-more__gradient')
    this.label = document.querySelector('.action-label')
  }

  sizeContainer() {
    if (!this.vph.isMobile()) {
      this.removeClass()
      this.container.classList.add('js-hidden')
    }

    const curHeight = this.content.offsetHeight

    if (CONTAINER_HEIGHT < curHeight) {
      this.addClass()
      this.container.classList.remove('js-hidden')
    }
  }

  initBehavior() {
    window.addEventListener('resize', () => this.sizeContainer())
    this.controls.addEventListener('click', () => this.toggle())
  }

  toggle() {
    if (this.controls.classList.contains('js-active')) {
      this.label.innerHTML = 'Mehr anzeigen'
      this.gradient.classList.remove('js-hidden')
      this.controls.classList.remove('js-active')
      this.addClass()
    } else {
      this.removeClass()
      this.gradient.classList.add('js-hidden')
      this.controls.classList.add('js-active')
      this.label.innerHTML = 'Weniger anzeigen'
    }
  }

  addClass() {
    this.content.classList.add(CLASS_NAME)
  }

  removeClass() {
    this.content.classList.remove(CLASS_NAME)
  }
}
