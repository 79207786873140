/* global $ document */
import { professionJobPostingsPath } from 'routes'
import AbPubSub from '../../javascript/src/lib/ab_pubsub'
import Events from '../../javascript/src/config/events'
import JobPostingBookmarkWidget from '../job_posting/bookmark/script'

const SIMILAR_PROF_CONTAINER_TMP_SELECTOR = '#js-similar-professions-container-template'
const SIMILAR_PROF_TMP_SELECTOR = '#js-similar-professions-template'
const SIMILAR_PROF_CTA_CARD_TMP_SELECTOR = '#js-similar-professions-cta-card-template'
const NO_PROFESSIONS_TMP_SELECTOR = '#js-no-professions-template'

export default class SimilarProfessionsWidget {
  constructor(insertSelector = '.js-similar-professions-job-postings-here') {
    this.pubSub = AbPubSub.getInstance()
    this.searchUrl = '/ajax/similar_professions/'
    this.similarProfessions = []
    this.$insertSelector = $(insertSelector)
    this.$similarProfContainerTemplate = $(SIMILAR_PROF_CONTAINER_TMP_SELECTOR).abLoadHTMLTemplate()
    this.$cardsTemplate = $(SIMILAR_PROF_TMP_SELECTOR).abLoadHTMLTemplate()
    this.$ctaTemplate = $(SIMILAR_PROF_CTA_CARD_TMP_SELECTOR).abLoadHTMLTemplate()
    this.$noProfessionsTemplate = $(NO_PROFESSIONS_TMP_SELECTOR).abLoadHTMLTemplate()
    this.searchParams = null
    this.jobPostingsOnPageCount = null
    this.timeout = null
    this.currentSimilarProfessionTitle = null

    this.registerListener()
  }

  registerListener() {
    this.pubSub.subscribe(Events.ON_MAIN_SEARCH_RESULTS_LOADED, (data) => {
      this.handleChange(data)
    })
  }

  handleChange(opts) {
    if (opts.jobPostingsLength >= 0 && opts.jobPostingsLength < 4) {
      this.searchParams = opts.searchParams
      this.fetchSimilarProfessionsJobPostings(opts['profession-public-id'])
    } else {
      this.clearSimilarJobPostingResults()
    }
  }

  clearSimilarJobPostingResults() {
    if (this.$inserted) {
      this.$inserted.remove()
      this.$inserted = null
    }
  }

  fetchSimilarProfessionsJobPostings(professionPublicId) {
    this.searchParams += `&form_main_search[profession_public_id]=${professionPublicId}`
    $.ajax({
      method: 'GET',
      url: '/ajax/similar_professions_search/',
      data: this.searchParams,
    })
      .done((response) => {
        if (response.length > 0) {
          this.hydrate(response)
        } else {
          this.insertNoProfessionsTemplate()
        }
      })
      .fail(this.searchFail.bind(this))
  }

  searchFail() {
    $('.js-similar-professions')
      .empty()
      .append('Ups! Das hat nicht geklappt, sorry! Versuch es bitte später noch mal, ok?')
  }

  hydrate(similars) {
    if (similars.length > 0) {
      this.similarProfessions = similars
      if (!this.$inserted) {
        this.$inserted = this.$similarProfContainerTemplate.insertBefore(this.$insertSelector)
      }
      this.similarJobPostingBookmarkWidget = new JobPostingBookmarkWidget('js-similar-professions-container')

      this.similarProfessions.forEach((profession, index) => {
        const html = this.createSimilarJobPostingsHtml(profession, index)
        $('.js-similar-professions').append(html)
      })
      this.pubSub.emit(Events.ON_RELOAD_LOGIN_LINKS)
      this.pubSub.emit(Events.ON_JOB_POSTINGS_LOADED, {
        parentSelector: '.similar-professions',
        selector: '.js-bookmark-job-posting',
        prefix: 'ASB_',
      })
    }
  }

  createSimilarJobPostingsHtml(profession, index) {
    this.currentSimilarProfessionTitle = profession.title
    const $template = this.$cardsTemplate.clone()
    const professionPath = professionJobPostingsPath(profession.slug)
    $template.find('.js-similar-professions-title').text(this.currentSimilarProfessionTitle)

    if (profession.jobPostings > 2) {
      $template.find('.js-similar-professions-more-job-postings').attr('href', professionPath).removeClass('js-hidden')
    }

    const $swiper = $template
      .find('.js-similar-professions-job-postings-swiper')
      .append(profession.similarJobPostingCard)

    if (profession.jobPostings >= 5) {
      const rotationClass =
        index % 2 == 0 ? 'js-similar-job-posting-cta--rotate-right' : 'js-similar-job-posting-cta--rotate-left'
      const $ctaCard = this.createCtaCard(this.currentSimilarProfessionTitle, professionPath).addClass(rotationClass)

      $swiper.append($ctaCard)
    }
    return $template
  }

  createCtaCard(profession, slug) {
    const $template = this.$ctaTemplate.clone()
    $template.find('.js-similar-job-posting-cta__profession').text(profession)
    $template.find('.js-similar-job-posting-cta__link').attr('href', slug).attr('target', '_blank')
    return $template
  }

  insertNoProfessionsTemplate() {
    if (!this.$inserted) {
      this.$inserted = this.$noProfessionsTemplate.insertBefore(this.$insertSelector)
    }
  }
}
