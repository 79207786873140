/* global $ window */

import debounce from 'lodash/debounce'
import LayoutVariables from '../../../javascript/src/config/layout_variables'
import ScrollableAnchorDropdown from './scrollable_anchor_dropdown'
import ViewportHelper from '../../../javascript/src/lib/viewport_helper'
import AbPubSub from '../../../javascript/src/lib/ab_pubsub'
import EVENTS from '../../../javascript/src/config/events'

const SCROLLABLE_ANCHOR_BOX_WIDGET_SELECTOR = '.scrollable-anchor-box'
const MAIN_NAVIGATION_SELECTOR = '.main-navigation'

export default class AbstractScrollableAnchorBoxWidget {
  constructor(contentContainerSelector = '.ab-cms-content') {
    this.pubSub = AbPubSub.getInstance()
    this.selector = SCROLLABLE_ANCHOR_BOX_WIDGET_SELECTOR
    this.$instance = $(this.selector)
    this.$footer = $('footer')
    this.$list = this.$instance.find(`${this.selector}__list`)
    this.$window = $(window)
    this.$nearestScrollParent = this.$instance.parents('[data-scrollable-parent]')
    this.initializedDropdown = false
    this.$mainNav = $(MAIN_NAVIGATION_SELECTOR)
    this.mainContent = contentContainerSelector
    this.viewPortHelper = ViewportHelper.getInstance()

    if (this.$instance.length && this.$list.length) {
      this.initList()
      this.initialListHeight = this.$list[0].scrollHeight
      this.getScrollMarks()
      this.initDropdownMode()
      this.scaleToMax()
      this.initScrollMechanic()
      this.initBehavior()
    }
  }

  initList() {
    this.$instance.parent().css('position', 'relative')
    const $parseParent = $('[data-scrollable-parse]')

    if (!$parseParent.length) {
      return
    }
    //
    const $anchors = $parseParent.find('.scrollable-anchor')

    $anchors.each((i, anchor) => {
      const $tmp = $(anchor)
      const $anchor = $('<a/>', {
        href: `#${$tmp.attr('id')}`,
        text: $tmp.attr('data-text-anchor'),
        class: `${this.selector}__link`.substring(1),
      })

      const $li = $('<li/>', {
        class: 'base-box__item scrollable-anchor-box__item',
      })

      const $downloadLink = this.$list.find('.scrollable-anchor-box__item--download-link')

      if ($downloadLink.length) {
        // the list is built from the bottom up
        $li.append($anchor).insertBefore($downloadLink)
      } else {
        this.$list.append($li.append($anchor))
      }
    })
  }

  getScrollMarks() {
    const $links = this.$instance.find(`${this.selector}__link`)
    this.scrollMarks = []

    $links.each((_index, elem) => {
      const $tempLink = $(elem)
      const id = $tempLink.attr('href')

      if (id[0] === '#') {
        this.scrollMarks.push({
          $link: $tempLink,
          $target: this.$nearestScrollParent.find(id),
          id,
        })
      }
    })
  }

  activateFirstItem() {
    this.activateItem($(`${this.selector}__link`).first())
  }

  initBehavior() {
    this.$instance.on('click', `${this.selector}__link`, this.openList.bind(this))
    this.$window.on('resize', this.onResize.bind(this))
    this.pubSub.subscribe(EVENTS.ON_SCROLL_TOP, this.activateFirstItem.bind(this))
  }

  initDropdownMode() {
    this.dropdown = new ScrollableAnchorDropdown(this.$instance, this.$list)
    this.dropdown.hide()
  }

  initScrollMechanic() {
    this.elemTop = this.$instance.offset().top
    const scrollHandler = debounce(this.onScroll.bind(this), 30, {
      maxWait: 100,
      leading: false,
      trailing: true,
    })
    this.$window.on('scroll', scrollHandler)
    this.onScroll()
  }

  onScroll() {
    const viewPortTop = this.$window.scrollTop()
    this.checkPosition(viewPortTop)
    if (!this.isCorpFaqPage()) this.fixatePosition(viewPortTop)
    if (viewPortTop === 0) {
      this.$instance.css({ top: 'auto', bottom: 'auto' })
      this.activateFirstItem()
      this.dropdown.reset()
    }
    this.scrollToActiveTab()
  }

  scrollToActiveTab() {
    const activeItem = $(`${this.selector}__item--active`)
    if (activeItem.position() !== undefined) {
      const activeItemPosition = activeItem.position().top

      if (activeItemPosition > this.$list.height()) {
        this.$list.animate({
          scrollTop: activeItemPosition,
        })
      }

      if (activeItemPosition < this.$list.position().top) {
        this.$list.animate({
          scrollTop: 0,
        })
      }
    }
  }

  fixatePosition(viewPortTop) {
    const tempHeight = this.$nearestScrollParent.offset().top + this.$nearestScrollParent.height()
    const maxScrollDepth = tempHeight - this.$instance.height() - this.$footer.height()

    const fixateBox = viewPortTop >= this.elemTop && viewPortTop < maxScrollDepth
    const rmBox = viewPortTop > maxScrollDepth
    const resetBox = viewPortTop < this.elemTop

    this.toggleClass('--fixed', fixateBox)
    this.toggleClass('--abs', rmBox)
    this.toggleClass('', resetBox)
  }

  toggleClass(className, flag) {
    const method = flag ? 'addClass' : 'removeClass'
    const element = `.${this.selector.substring(1)}${className}`
    this.$instance[method](`${this.selector.substring(1)}${className}`)

    switch (className) {
      case '--fixed':
        $(element).css({
          top: this.$mainNav.height(),
          bottom: 'auto',
        })
        break
      case '--abs':
        $(element).css({
          top: 'auto',
          bottom: '0',
        })
        break
      default:
        $(element).css({
          top: 'auto',
          bottom: 'auto',
        })
        break
    }
  }

  onResize() {
    this.elemTop = this.$instance.parent().offset().top
    this.scaleToMax()
  }

  scaleToMax() {
    const windowWidth = this.$window.width()

    if (windowWidth >= LayoutVariables.desktopWidth) {
      const windowHeight = this.$window.innerHeight()
      const padding = parseFloat(
        $(this.mainContent)
          .css('padding-top')
          .replace(/[A-Za-z]/g, '')
      )

      this.$instance.innerHeight(
        this.$instance[0].scrollHeight >= windowHeight ? windowHeight - this.$mainNav.height() - padding - 20 : 'auto'
      )
      const offsetHeight = this.$instance.find(`${this.selector}__header`).outerHeight()
      const listHeight = Math.round(this.initialListHeight + offsetHeight)
      this.$list.height(listHeight >= this.$instance.innerHeight() ? this.$instance.height() - offsetHeight : 'auto')

      this.dropdown.hide()
    } else {
      this.dropdown.show()
      this.$list.attr('style', '') // otherwise the dropdown will have a height
      this.$instance.attr('style', '')
    }

    this.$instance.innerWidth(this.$instance.parent().width())
  }

  checkPosition(viewPortTop) {
    this.scrollMarks.forEach((mark) => {
      // 1px offset fixes problems with items not being activated
      if (mark.$target.offset()) {
        const hitsStartY = viewPortTop >= Math.ceil(mark.$target.offset().top) - 5

        if (hitsStartY) {
          this.activeMark = mark
          this.activateItem(this.activeMark.$link)
        }
      }
    })
  }

  openList(event) {
    this.activateItem($(event.target))
  }

  activateItem($item) {
    const itemSelector = `${this.selector}__item`
    const activeSelector = `${itemSelector}--active`
    const activeClass = activeSelector.substring(1)

    $(activeSelector).removeClass(activeClass)
    $item.parents(itemSelector).addClass(activeClass)

    if (this.$window.width() < LayoutVariables.desktopWidth) {
      this.dropdown.copyActive($item)
    }
  }

  isCorpFaqPage() {
    return this.mainContent !== '.corporation-faqs' && this.viewPortHelper.isMobile()
  }
}
