/* global $ */
import LayoutVariables from '../../../javascript/src/config/layout_variables'
import AjaxSpinnerWidget from '../../ajax_spinner/script'

const SUBMIT_BUTTON = '.btn-filled'

export default class ProfessionQuizLastStep {
  constructor() {
    this.$submitButton = $(SUBMIT_BUTTON)
    this.ajaxSpinner = new AjaxSpinnerWidget()
    this.initSpinner()

    this.resizeWindow = this.resizeWindow.bind(this)

    this.resizeWindow()

    $(window).on('resize', this.resizeWindow)
  }

  initSpinner() {
    this.$submitButton.on('click', () => {
      this.ajaxSpinner.showSpinner()
      this.$submitButton.hide()
    })
  }

  resizeWindow() {
    const viewportWidth = $(window).width()
    const height = $(window).innerHeight()
    let navBarSize

    if (viewportWidth < LayoutVariables.tabletWidth) {
      navBarSize = 45
    } else if (viewportWidth < LayoutVariables.desktopWidth) {
      navBarSize = 43
    } else {
      navBarSize = 60
    }

    $('.profession-quiz-step').each(function () {
      $(this).css('height', `calc(${height}px - ${navBarSize}px)`)
    })
  }
}
