/* global $ */

const COLOR_LIGHT = '#EDE5FF'
const COLOR_MEDIUM = '#D1BDFA'
const COLOR_DARK = '#5E2DC1'

const PROFESSION_CARD = '.profession-quiz-card'
const BUTTON = '.btn-outline'

export default class ProfessionQuizCard {
  constructor() {
    this.$card = $(PROFESSION_CARD)
    this.$button = $(BUTTON)

    this.activateCard()
  }

  activateCard() {
    this.$card.on('click', (event) => {
      const $clickedCard = $(event.currentTarget)
      $clickedCard.append('<div class="animation-background"></div>')

      const $animationBackground = $clickedCard.find('.animation-background')

      $animationBackground.css({
        animation: 'rippleEffect 0.2s ease',
        'background-color': COLOR_LIGHT,
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        'border-radius': '1.2rem',
        'z-index': -1,
      })
      $clickedCard.find(BUTTON).css({
        'border-color': COLOR_MEDIUM,
        'background-color': COLOR_LIGHT,
        color: COLOR_DARK,
      })
      setTimeout(() => {
        $clickedCard.css('border-color', COLOR_DARK)
      }, 200)
    })
  }
}
