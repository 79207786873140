/* global $ */
import Page from '../../page'

import ActiveSourcingPrivacyFormWidget from '../../../../../widgets/active_sourcing_privacy_form/script'
import ClickAfterLogin from '../../../lib/click_after_login'

const FILL_PROFILE_BUTTON_SELECTOR = '.js-click-after-login'

export default class ActiveSourcing extends Page {
  constructor() {
    super()
    new ActiveSourcingPrivacyFormWidget()
    new ClickAfterLogin('fillProfileIntro', $(FILL_PROFILE_BUTTON_SELECTOR))
  }
}
