export default class CityProfessionLinkListWidget {
  get showAllProfessionsSelector() {
    return '.js-show-all-professions'
  }
  get linkListSelector() {
    return '.js-link-list'
  }

  constructor() {
    this.$showProfessionsButton = $(this.showAllProfessionsSelector)
    this.$linkList = $(this.linkListSelector)

    this.$showProfessionsButton.on('click', () => {
      if (this.$linkList.hasClass('link-list--closed')) {
        this.$linkList
          .removeClass('link-list--closed link-list--fade-out')
          .addClass('link-list--open')
        this.$showProfessionsButton.text('Weniger Berufe anzeigen')
      } else {
        this.$linkList
          .removeClass('link-list--open')
          .addClass('link-list--closed link-list--fade-out')
        this.$showProfessionsButton.text('Alle Berufe anzeigen')
      }
    })
  }
}
