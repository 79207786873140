/* global $ */
import Page from '../../page'

import ReviewsWidget from '../../../../../widgets/reviews/script'

export default class Reviews extends Page {
  constructor() {
    super()
    this.reviewsWidget = new ReviewsWidget()
  }
}
