/* global $ */
export default class VacancyCountBreakerWidget {
  get VacancyCountBreakerSelector() {
    return 'ab-cms-container--vacancy-count-breaker'
  }

  get VacancyCountBreakerCountSelector() {
    return 'vacancy-count-breaker__count'
  }

  constructor() {
    this.VacancyCountBreakers = document.getElementsByClassName(this.VacancyCountBreakerSelector)
    this.searchUrl = '/ajax/main_search_result_count/'

    if (this.VacancyCountBreakers.length > 0) this.triggerSearch()
  }

  triggerSearch() {
    Array.from(this.VacancyCountBreakers).forEach(elem => {
      const searchUrl = new URL(elem.querySelector('a').href)
      const searchParams = new URLSearchParams(searchUrl.search)

      // add default radius params if not set in search
      if (!searchParams.get('form_main_search[radius]') && searchParams.toString().includes('form_main_search')) {
        searchParams.append('form_main_search[radius]', '1000')
      }
      const resultsCountBlob = elem.querySelector(
        `.${this.VacancyCountBreakerCountSelector} > .blob`
      )

      $.ajax({
        url: this.searchUrl,
        data: searchParams.toString(),
        dataType: 'json',
        error: () => {
          resultsCountBlob.textContent = '0'
        },
        success: result => {
          resultsCountBlob.style.width = 'auto'
          resultsCountBlob.textContent = JSON.parse(result.results_count)
        }
      })
    })
  }
}
