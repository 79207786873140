/* global $ window */
import AirDatepicker from 'air-datepicker'
import localeDe from 'air-datepicker/locale/de'
import AbPubSub from '../../../javascript/src/lib/ab_pubsub'
import Events from '../../../javascript/src/config/events'
import CityProfessionLinkListWidget from '../../city_profession_link_list/script'
import UtilityHelper from '../../../javascript/src/lib/utility_helper'

import 'air-datepicker/air-datepicker.css'
import '../../../assets/stylesheets/components/air-datepicker-overwrites.css'

export default class FilterBoxWidget {
  get formFilterBoxSelector() {
    return '.js-filter-box-form'
  }

  get rangeTextSelector() {
    return '.similar-professions__suggestions--rangetext'
  }

  get rangeSliderOuterSelector() {
    return '.js-rangeslider'
  }

  get rangeSelector() {
    return 'input[type=range]'
  }

  get rangeOutputSelector() {
    return '.range-output'
  }

  get professionSelectSelector() {
    return '.js-selectize-profession'
  }

  get professionTopicSelector() {
    return '.js-selectize-profession-topics'
  }

  get professionTopicMultiselect() {
    return '.js-generic-multiselct-profession-topics'
  }

  get industrySelectSelector() {
    return '.js-selectize-industry'
  }

  get expectedGraduationSelectSelector() {
    return '.js-selectize-expected-graduation'
  }

  get showFiltersSelector() {
    return '.js-show-filters'
  }

  constructor() {
    this.form = $(this.formFilterBoxSelector)
    this.baseName = 'form_main_search_filter'
    this.cityProfessionLinkList = new CityProfessionLinkListWidget()

    if (!this.hasFilterBox()) {
      // eslint-disable-next-line no-console
      console.error(
        'MainSearchFilterBox was initialized, but could not be found in the document. Make sure to render the MainSearchFilterBox::Widget.'
      )
    } else {
      this.$rangeSlider = $(this.rangeSelector)
      this.$rangeOutput = $(this.rangeOutputSelector)
      this.$rangeOuterElement = $(this.rangeSliderOuterSelector)
      this.$filterToggle = $(this.showFiltersSelector)

      this.expectedGraduationSelect = $(this.expectedGraduationSelectSelector).selectize({ sortField: 'text' })
      this.professionSelect = $(this.professionSelectSelector).selectize({ sortField: 'text' })
      this.industrySelect = $(this.industrySelectSelector).selectize({ sortField: 'text' })
      this.professionTopicSelect = $(this.professionTopicSelector).selectize({ sortField: 'text' })

      const datepickerWrapper = $('.filter-box__datepicker-wrapper')
      this.$datepicker = datepickerWrapper.find('.js-datepicker-here')

      this.abPubSub = AbPubSub.getInstance()

      this.dataDL = {
        expectedGraduationFilter: '',
        professionFilter: '',
        professionTopicsFilter: '',
        industryFilter: '',
        apprenticeshipTypes: 'Ausbildung; Schulisch; DS; Weiterbildung; Betriebsintern; Abiprogramm; SchulDual',
      }
    }
  }

  initFilterBox(searchCallback) {
    if (this.hasFilterBox()) {
      this.initDatePicker()
      this.initRangeSlider(searchCallback)

      this.$filterToggle.on('click', () => {
        $('.js-filters').toggleClass('filters-hidden')
        this.$filterToggle.toggleClass('open')
      })

      this.initExpectedGraduationSelectize()
      this.initIndustrySelectize()
      this.initProfessionSelectize()
      this.initProfessionTopicSelectize()
      this.initApprenticeshipTypesSelectize()
    }
  }

  getSelectizeValue(selectElement) {
    // const [select] = selectElement
    // const [selectize] = select
    const selectize = selectElement[0].selectize
    if (selectize.items.length) {
      return selectize.options[selectize.items].text
    }
    return ''
  }

  initExpectedGraduationSelectize() {
    this.dataDL.expectedGraduationFilter = this.getSelectizeValue(this.expectedGraduationSelect)
    this.initFilterSelectize({
      selectize: this.expectedGraduationSelect[0].selectize,
      changedValueName: 'expected_graduation',
      dataLayerName: 'expectedGraduationFilter',
    })
  }

  initProfessionSelectize() {
    this.dataDL.professionFilter = this.getSelectizeValue(this.professionSelect)
    this.initFilterSelectize({
      selectize: this.professionSelect[0].selectize,
      changedValueName: 'profession_public_id',
      dataLayerName: 'professionFilter',
    })
  }

  initProfessionTopicSelectize() {
    this.dataDL.professionTopicsFilter = this.getSelectizeValue(this.professionTopicSelect)
    this.initFilterSelectize({
      selectize: this.professionTopicSelect[0].selectize,
      changedValueName: 'profession_topic_public_id',
      dataLayerName: 'professionTopicsFilter',
    })
  }

  initIndustrySelectize() {
    this.dataDL.industryFilter = this.getSelectizeValue(this.industrySelect)
    this.initFilterSelectize({
      selectize: this.industrySelect[0].selectize,
      changedValueName: 'industry_public_id',
      dataLayerName: 'industryFilter',
    })
  }

  initFilterSelectize({ selectize, changedValueName, dataLayerName }) {
    selectize.on('change', () => {
      this.filterValueChanged(changedValueName, ...selectize.items)
      if (selectize.items.length) {
        this.updateDataDL(dataLayerName, selectize.options[selectize.items].text)
      }
    })
  }

  updateDataDL(prop, value) {
    this.dataDL[prop] = value
  }

  initApprenticeshipTypesSelectize() {
    const self = this
    const apprenticeshipObj = [
      ['form_main_search_filter[show_regular_apprenticeships]', 'Ausbildung', 'active'],
      ['form_main_search_filter[show_educational_trainings]', 'Schulisch', 'active'],
      ['form_main_search_filter[show_integrated_degree_programs]', 'DS', 'active'],
      ['form_main_search_filter[show_qualifications]', 'Weiterbildung', 'active'],
      ['form_main_search_filter[show_inhouse_trainings]', 'Betriebsintern', 'active'],
      ['form_main_search_filter[show_training_programs]', 'Abiprogramm', 'active'],
      ['form_main_search_filter[show_educational_trainings_and_regular_apprenticeships]', 'SchulDual', 'active'],
    ]
    function updateActiveItemsString() {
      const activeItemsArray = []
      // eslint-disable-next-line func-names
      $('.filter-box__apprenticeship-types .checkbox__box').each(function () {
        const $checkbox = $(this)
        const checkboxName = $checkbox.attr('name')
        const isChecked = $checkbox.is(':checked')
        if (isChecked) {
          const matchingItem = apprenticeshipObj.find((item) => item[0] === checkboxName)
          if (matchingItem) {
            activeItemsArray.push(matchingItem[1])
          }
        }
      })
      const semicolonSeparatedString = activeItemsArray.join(';')
      self.updateDataDL('apprenticeshipTypes', semicolonSeparatedString)
    }
    $('.filter-box__apprenticeship-types .checkbox__box').on('change', () => {
      updateActiveItemsString()
    })
    updateActiveItemsString()
  }

  initRangeSlider(loadCallback) {
    let timeoutHandler = null
    const self = this
    this.$rangeSlider.rangeslider({
      polyfill: false,
      onInit() {
        self.$rangeOutput.text($(this.rangeSelector).val())
      },
      onSlide(_position, value) {
        self.$rangeOutput.text(value)
      },
      onSlideEnd(_position, value) {
        self.$rangeOutput.text(value)

        if (timeoutHandler) {
          clearTimeout(timeoutHandler)
        }

        timeoutHandler = setTimeout(() => {
          self.filterValueChanged('radius', value)
          loadCallback()
        }, 100)
      },
    })
  }

  disableRangeSlider() {
    this.$rangeSlider.val(1000)
    this.$rangeOutput.text(1000)
    this.$rangeOuterElement.addClass('filter-box__range-inline--disabled')
    this.$rangeSlider.prop('disabled', true)
    this.$rangeSlider.rangeslider('update', true)
    this.filterValueChanged('radius', 1000)

    // update radius in url params
    const params = UtilityHelper.getUrlParams(window.location.href)
    params['form_main_search[radius]'] = 1000
    const paramString = Object.keys(params)
      .map((key) => `${key}=${params[key]}`)
      .join('&')

    window.history.replaceState(params, '', `?${paramString}`)
  }

  enableRangeSlider() {
    const filterBoxRangeClass = 'filter-box__range-inline--disabled'
    if (this.$rangeOuterElement.hasClass(filterBoxRangeClass)) {
      this.$rangeSlider.val(20)
      this.$rangeOutput.text(20)
    }
    this.$rangeOuterElement.removeClass(filterBoxRangeClass)
    this.$rangeSlider.prop('disabled', false)
    this.$rangeSlider.rangeslider('update', true)
    this.filterValueChanged('radius', 20)
  }

  initDatePicker() {
    const minDate = new Date()
    minDate.setDate(1)
    minDate.setMonth(minDate.getMonth() - 1)

    this.airDatepicker = new AirDatepicker('.js-datepicker-here', {
      minDate,
      position: 'bottom center',
      locale: localeDe,
      container: '.datepicker-container',
      view: 'years',
      minView: 'months',
      dateFormat: 'MMMM yyyy',
      autoClose: true,

      onHide: (isFinished) => {
        if (isFinished) {
          this.$datepicker.trigger('change')
        }
      },
      onSelect: (selectedDate) => {
        this.filterValueChanged('starts_no_earlier_than', selectedDate.formattedDate)
      },
    })
  }

  hasFilterBox() {
    return this.form.length > 0
  }

  getFilterParams() {
    const values = this.form.serializeArray()
    return values
      .filter((value) => value.name !== 'utf8')
      .map((value) => ({
        // TODO: Writer proper replace
        name: value.name.replace(this.baseName, '').replace('[', '').replace(']', ''),
        value: value.value,
      }))
  }

  filterValueChanged(name, value) {
    this.abPubSub.emit(Events.ON_CHANGE_FILTER_BOX, { name, value })
  }

  resetFilterValue(name) {
    switch (name) {
      case 'profession_public_id':
        this.professionSelect[0].selectize.clear(false)
        this.filterValueChanged(name, '')
        break
      case 'profession_topic_public_id':
        this.professionTopicSelect[0].selectize.clear(false)
        this.filterValueChanged(name, '')
        break
      case 'expected_graduation':
        this.expectedGraduationSelect[0].selectize.clear(false)
        this.filterValueChanged(name, '')
        break
      case 'industry_public_id':
        this.industrySelect[0].selectize.clear(false)
        this.filterValueChanged(name, '')
        break
      case 'radius':
        this.$rangeSlider.val(20).trigger('change')
        this.$rangeOutput.text(20)
        this.filterValueChanged(name, 20)
        break
      case 'starts_no_earlier_than':
        this.airDatepicker.clear()
        this.filterValueChanged(name, '')
        this.$datepicker.trigger('change')
        break
      default:
        break
    }
  }
}
