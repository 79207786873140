/* global $, window */
const FORM = '#new_privacy_form'
const PRIVACY_FORM = '.privacy-form-box'
const ERROR_CLASS = '.field_with_errors'

export default class ActiveSourcingPrivacyFormWidget {
  constructor() {
    const $errorCount = $(`${PRIVACY_FORM} ${ERROR_CLASS}`)

    if ($errorCount.length) {
      window.scrollTo(0, $(FORM).offset().top)
    }
  }
}
