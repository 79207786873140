/* global $ */

const SELECTIZE_SELECTOR = '.js-selectize'

export default class SingleSelectInputWidget {
  constructor() {
    const selects = $(SELECTIZE_SELECTOR)

    for (let i = 0; i < selects.length; i++) {
      const customConfig = $(selects[i]).data('selectize-config') || {}
      const config = $.extend(
        {
          create: false
        },
        customConfig
      )
      $(selects[i]).selectize(config)
    }
  }
}
