import Page from '../../page'
import MainSearchWithResultsWidget from '../../../../../widgets/main_search_with_results/script'
import CorporationsCarouselWidget from '../../../../../widgets/corporations/carousel/script'
import SimilarProfessionsWidget from '../../../../../widgets/similar_professions/script'
import ShowMore from '../../../lib/show_more'
import ViewportHelper from '../../../lib/viewport_helper'
import NearbyProfessions from '../../../../../widgets/alternative_content/nearby_professions/script'

export default class CityProfessionPage extends Page {
  constructor() {
    super()
    this.nearby = new NearbyProfessions(this.pubSub)
    this.mainSearchWithResultsWidget = new MainSearchWithResultsWidget(false, 24, false)
    this.corporationCarouselWidget = new CorporationsCarouselWidget()
    this.similarProfessionsWidget = new SimilarProfessionsWidget()
    this.showMore = new ShowMore(ViewportHelper.getInstance())
  }
}
