/* global $ */

import cookie from 'js-cookie'
import AbPubSub from '../../javascript/src/lib/ab_pubsub'
import Events from '../../javascript/src/config/events'
import FlashMessagesWidget from '../flash_messages/script'

export default class JobletterButtonWidget {
  get jobletterButtonSelector() {
    return '.js-jobletter'
  }

  constructor() {
    this.$jobletterButton = $(this.jobletterButtonSelector)
    this.jobletterPath = this.$jobletterButton.data('jobletterPath')
    this.initiateListener()
    this.initBehavior()
    this.registerJobLetterOnLogin()
    this.flash = new FlashMessagesWidget()
  }

  initBehavior() {
    this.$jobletterButton.on('click', this.toggleJobletterSubscription.bind(this))
  }

  toggleJobletterSubscription() {
    const method = this.desiredAction()
    const successCallback = this.handleSuccess.bind(this)
    const errorCallback = this.handleError.bind(this)

    $.ajax({
      url: this.jobletterPath,
      method,
      data: { jobletter: this.getJobletterParams() },
      success: successCallback,
      error: errorCallback,
    })
  }

  handleSuccess() {
    if (this.$jobletterButton.hasClass('js-unsubscribe')) {
      this.$jobletterButton.text('Suche abonnieren')
      this.$jobletterButton.removeClass('js-unsubscribe')
      this.$jobletterButton.addClass('js-subscribe')
      this.flash.showSuccessMessage('Diese Suche wurde abbestellt.')
    } else {
      this.$jobletterButton.text('Suche abonniert')
      this.$jobletterButton.removeClass('js-subscribe')
      this.$jobletterButton.addClass('js-unsubscribe')
      this.$jobletterButton.attr('disabled', true)
      this.flash.showSuccessMessage('Diese Suche wurde abonniert. Du erhältst ab sofort passende Stellen per E-Mail.')
    }
  }

  handleError() {
    this.flash.showErrorMessage('Bitte gib mindestens ein Suchkriterium an.')
    this.$jobletterButton.off()
    this.$jobletterButton.removeClass('btn-outline--orange')
    this.$jobletterButton.addClass('btn-outline--disabled')
  }

  desiredAction() {
    if (this.$jobletterButton.hasClass('js-unsubscribe')) {
      return 'delete'
    }

    return 'post'
  }

  initiateListener() {
    const abPubSub = AbPubSub.getInstance()

    abPubSub.emit(Events.ON_RELOAD_LOGIN_LINKS)
  }

  getJobletterParams() {
    const data = this.$jobletterButton.data().searchParams

    return {
      longitude: data.longitude,
      latitude: data.latitude,
      what: data.what,
      where: data.where,
      radius: data.radius,
      show_regular_apprenticeships: data.show_regular_apprenticeships,
      show_educational_trainings: data.show_educational_trainings,
      show_integrated_degree_programs: data.show_integrated_degree_programs,
      show_qualifications: data.show_qualifications,
      show_inhouse_trainings: data.show_inhouse_trainings,
      show_training_programs: data.show_training_programs,
      show_educational_trainings_and_regular_apprenticeships:
        data.show_educational_trainings_and_regular_apprenticeships,
      expected_graduation: data.expected_graduation,
      profession_public_id: data.profession_public_id,
      industry_public_id: data.industry_public_id,
      starts_no_earlier_than: data.starts_no_earlier_than,
      origin: data.origin,
      profession_topic_public_id: data.profession_topic_public_id,
    }
  }

  registerJobLetterOnLogin() {
    const popUpValue = cookie.get('triggerPopUp')
    if (popUpValue === 'jobletterBottom' || popUpValue === 'jobletterTop') {
      cookie.remove('triggerPopUp')
      const btnText = this.$jobletterButton.text()
      if (btnText.includes('abonnieren')) {
        this.toggleJobletterSubscription()
      }
    }
  }
}
