/* global $ */

export default class SliderWidget {
  get namespace() {
    return '.js-slider'
  }

  get mainSelector() {
    return '.js-slider__main'
  }

  get navSelector() {
    return '.js-slider__nav'
  }

  get prevSelector() {
    return '.js-slider__arrows--prev'
  }

  get nextSelector() {
    return '.js-slider__arrows--next'
  }

  //
  constructor() {
    this.initSlider()
  }

  initSlider() {
    $(this.namespace).each((_i, el) => {
      const $el = $(el)
      const uuid = $el.data('uuid')
      const uniqNavSel = `${this.navSelector}--${uuid}`
      const uniqMainSel = `${this.mainSelector}--${uuid}`
      const $nav = $el.find(uniqNavSel)
      const useNav = $nav.length
      const uniqMainConfig = $el.find(uniqMainSel).data('options')
      const $slider = $el.find(uniqMainSel).slick(this.getSlickConfig(uniqMainConfig, useNav, uniqNavSel))

      $slider.on('click', '.js-slider__arrows', (event) => {
        const dir = $(event.currentTarget).data('dir')
        const arrow = `${dir.slice(0, 1).toUpperCase()}${dir.slice(1)}`
        return $slider.slick(`slick${arrow}`)
      })

      if (useNav) {
        const uniqNavConfig = $nav.data('options')
        uniqNavConfig.asNavFor = uniqMainSel
        $nav.slick(uniqNavConfig)
      }
    })
  }

  getSlickConfig(uniqMainConfig, useNav, uniqNavSel) {
    const cfg = uniqMainConfig

    if (useNav) {
      cfg.asNavFor = uniqNavSel
    }

    return cfg
  }
}
