/* global window $ */

import AbstractContactForm from '../../javascript/src/lib/abstract_contact_form'
import Events from '../../javascript/src/config/events'
import AbPubSub from '../../javascript/src/lib/ab_pubsub'

export default class GeneralContactFormWidget extends AbstractContactForm {
  get loginBar() {
    return document.getElementsByClassName('navigation__login')[0]
  }

  get openFormButton() {
    return document.getElementById('js-contact-form-open-form')
  }

  get noOtherClickEvents() {
    return this.noOtherEvents
  }

  constructor() {
    super('contact-form')
    this.noOtherEvents = true
    this.abPubSub = AbPubSub.getInstance()
    //
    this.checkUserStatus()
    this.enableEventDelegation()
  }

  checkUserStatus() {
    if (this.loginBar.getElementsByClassName('js-open_login_modal').length) {
      if (this.openFormButton) this.openFormButton.classList.add('js-open_login_modal')
      this.noOtherEvents = false
    } else if (this.loginBar.getElementsByClassName('js-open_activation_modal').length) {
      if (this.openFormButton) this.openFormButton.classList.add('js-open_activation_modal')
      this.noOtherEvents = false
    } else if (this.loginBar.getElementsByClassName('js-open_email_update_modal').length) {
      if (this.openFormButton) this.openFormButton.classList.add('js-open_email_update_modal')
      this.noOtherEvents = false
    }
  }

  enableEventDelegation() {
    if (this.openFormButton) {
      this.openFormButton.addEventListener('click', e => {
        if (e.target) {
          if (e.target.classList.contains('js-open_login_modal')) {
            this.triggerPubSubEvent(e, Events.ON_OPEN_LOGIN_MODAL)
          } else if (e.target.classList.contains('js-open_email_update_modal')) {
            this.triggerPubSubEvent(e, Events.ON_OPEN_EMAIL_UPDATE_MODAL)
          } else if (e.target.classList.contains('js-open_activation_modal')) {
            this.triggerPubSubEvent(e, Events.ON_OPEN_ACTIVATION_MODAL)
          }
        }
      })
    }
  }

  triggerPubSubEvent(event, abEvent) {
    this.abPubSub.emit(abEvent)
    event.preventDefault()
    event.stopPropagation()
  }

  registerOpenCloseForm() {
    this.$openLink.on('click', e => {
      if (this.noOtherClickEvents) {
        e.preventDefault()
        $.ajax({
          method: 'GET',
          url: '/ajax/contact_author'
        }).done(response => {
          if (response.mail) {
            this.$form.find('#js-contact-form-email').val(response.mail)
          }
          this.showForm()
        })
      }
    })

    this.$closeLink.on('click', () => {
      this.hideForm()
    })
  }
}
