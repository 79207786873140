/* global window */
import $ from '../jquery'

// custom libs
import AbLoadHTMLTemplate from '../src/lib/html_load_template'

// extending jQuery
new AbLoadHTMLTemplate()

require('jquery-ujs')
require('slick-carousel')
require('rangeslider.js')
// Required for CMS images
require('lazysizes')
window.validate = require('validate.js')
window.Selectize = require('selectize')

window.GetSelectizeOnBlurConfig = (id) => {
  const selector = `selectize-on-blur-id-${id}`
  return {
    findAs: selector,
    onDropdownClose: ($dropdown) => {
      $($dropdown).find('.selected').not('.active').removeClass('selected')
    },
    onFocus: () => {
      const $el = $(`.${selector}`)
      $el.data('previousValue', $el.find('.item').data('value'))
      $el.data('previousText', $el.find('.item').text())
    },
    onBlur: () => {
      const sel = $(`.${selector}`)[0].selectize
      const searchResults = sel.currentResults
      const hasSearchResults = searchResults.total > 0
      // Since we don't get an `itemSelected` event or `onItemAdd` before
      // `onBlur` we have to infer whether the user clicked an item
      // or simply clicked away. After some experimentation this was the
      // most reliable indicator.
      const isPossibleDirectItemClick = searchResults.query === ''

      if (hasSearchResults && isPossibleDirectItemClick) {
        // Do nothing, the user has clicked on a search result and this
        // is handled somewhere else
      } else if (hasSearchResults) {
        // The user clicked somewhere else, select the topmost result for her
        const id = searchResults.items[0].id
        sel.setValue(id)
      } else {
        // Do nothing, the user clicked away with no search results
      }
    },
  }
}

// AB-2991: NodeList was missing forEach(), which broke selectize initialization
// That’s why we add it here explicitely
if (window.NodeList && !NodeList.prototype.forEach) {
  NodeList.prototype.forEach = Array.prototype.forEach
}
