// BE AWARE: js logic of this widget is also used in PrivacySocialMediaConsents
/* eslint-disable no-param-reassign */
import ABCookieManager from '../../javascript/src/lib/ab_cookie_manager'

const CONSENT_CARD_SELECTOR = '.js-social-media-consent-card'
const SOCIAL_MEDIA_ELEMENT_CLASS_SELECTOR = 'js-social-media-element'
const CONSENT_INFO_CLASS = 'js-social-media-consent-info'
const CONSENT_TOGGLE_CLASS_SELECTOR = 'js-consent-toggle'
const EMBED_CODE_SELECTOR = '.js-social-media-embed'
const SOCIAL_MEDIA_COOKIE_NAME = 'SocialMediaConsent'

export default class SocialMediaElementWidget {
  constructor(isPrivacyPage = false) {
    this.cookie = ABCookieManager.getInstance()
    this.socialMediaElementsArray = this.findSocialMediaElements()
    this.isPrivacyPage = isPrivacyPage

    this.initToggleListeners()

    // check if cookieBot Banner was triggered on a page with potential social media contents
    this.observer = new MutationObserver(this.checkForCookieBotBanner.bind(this))
    this.observer.observe(document.body, {
      attributes: false,
      childList: true,
      subtree: false
    })
  }

  checkForCookieBotBanner() {
    const banner = document.getElementById('CybotCookiebotDialog')

    if (banner) {
      // when banner is showing check if a user accepted marketing cookies
      // to show all social media contents when he/she deals with the banner
      const marketingToggle = document.getElementById('CybotCookiebotDialogBodyLevelButtonMarketing')
      const confirmAllBtn = document.getElementById('CybotCookiebotDialogBodyLevelButtonLevelOptinAllowAll')
      const confirmChoiceBtn = document.getElementById('CybotCookiebotDialogBodyLevelButtonLevelOptinAllowallSelection')

      if (confirmAllBtn) { confirmAllBtn.addEventListener('click', () => { if (marketingToggle.checked) { this.showAllSocialMediaContents() } }) }
      if (confirmChoiceBtn) { confirmChoiceBtn.addEventListener('click', () => { if (marketingToggle.checked) { this.showAllSocialMediaContents() } }) }

      this.observer.disconnect()
    }
  }

  findSocialMediaElements() {
    return Array.from(document.getElementsByClassName(SOCIAL_MEDIA_ELEMENT_CLASS_SELECTOR))
  }

  initToggleListeners() {
    const toggles = document.getElementsByClassName(CONSENT_TOGGLE_CLASS_SELECTOR)
    if (toggles.length > 0) {
      Array.from(toggles).forEach(toggle => {
        toggle.addEventListener('click', (e) => {
          const platform = e.target.value
          const consentGiven = e.target.checked

          if (!this.isPrivacyPage) {
            e.target.disabled = true
          }

          this.setCookie(platform, consentGiven)
          this.showSocialMediaContentsOnToggle(platform)
        })
      })
    }
  }

  setCookie(platform, consentGiven) {
    const socialMediaCookie = this.cookie.read(SOCIAL_MEDIA_COOKIE_NAME)
      ? JSON.parse(this.cookie.read(SOCIAL_MEDIA_COOKIE_NAME)) : {}
    this.cookie.remove(SOCIAL_MEDIA_COOKIE_NAME)

    socialMediaCookie[platform] = consentGiven
    socialMediaCookie.utc = Date.now()

    // we do not need an expiration date, however chrome e.g. sets it automatically to 400 days
    this.cookie.create(SOCIAL_MEDIA_COOKIE_NAME, socialMediaCookie, 1000)
  }

  showSocialMediaContentsOnToggle(platform) {
    this.socialMediaElementsArray.forEach((elem) => {
      const elemPlatform = elem.getAttribute('data-platform')

      if (elemPlatform === platform) {
        this.handleConsentGiven(elem)
      }
    })
  }

  showAllSocialMediaContents() {
    this.socialMediaElementsArray.forEach((elem) => {
      this.handleConsentGiven(elem)
    })
  }

  loadEmbedCode(elem) {
    let embedCode = elem.getAttribute('data-content')
    const embedDomElem = elem.querySelector(EMBED_CODE_SELECTOR)
    const domParser = new DOMParser()
    const parsedEmbedCode = domParser.parseFromString(embedCode, 'text/html')

    // get all script tags from embed code
    const scriptTags = Array.from(parsedEmbedCode.getElementsByTagName('script'))
    const scriptSrcList = scriptTags.map(scriptTag => scriptTag.getAttribute('src'))

    // remove scripts from embed code
    scriptTags.forEach(scriptTag => scriptTag.remove())

    // retrieve the modified HTML
    embedCode = parsedEmbedCode.documentElement.outerHTML

    // add embed HTML Code
    embedDomElem.innerHTML += embedCode

    // load potential scripts for execution
    scriptSrcList.forEach(src => {
      const scriptElem = document.createElement('script')
      scriptElem.src = src
      scriptElem.async = true
      embedDomElem.appendChild(scriptElem)
    })

    // add consent info paragraph at the end
    const consentInfoElem = document.createElement('p')
    consentInfoElem.classList.add(CONSENT_INFO_CLASS)
    consentInfoElem.innerHTML = elem.getAttribute('data-info-text')
    embedDomElem.appendChild(consentInfoElem)
  }

  handleConsentGiven(elem) {
    // remove consent card
    elem.querySelector(CONSENT_CARD_SELECTOR).remove()
    // some necessary styling
    elem.querySelector(EMBED_CODE_SELECTOR).style.height = '100%'

    // load the embed code
    this.loadEmbedCode(elem)
  }
}
