const presence = {
  presence: {
    allowEmpty: false,
    message: '^Bitte fülle dieses Feld aus.'
  }
}

const numericality = {
  numericalityWithAllowEmptyString: {
    message: '^Bitte gib nur Zahlen ein.'
  }
}

const email = {
  email: {
    message: '^Ungültige E-Mail'
  }
}

const step2Validations = {
  a: {
    reviewed_company: presence,
    city_id: presence
  },
  b: {
    reviewed_company: presence,
    reviewed_university: presence,
    city_id: presence
  },
  c: {
    school: presence,
    city_id: presence
  }
}

const step3Validations = {
  reviewed_profession: presence,
  working_hours_per_day: presence,
  start_year: presence,
  salary_year_one: numericality,
  salary_year_two: numericality,
  salary_year_three: numericality,
  salary_year_four: numericality
}

const step4Validations = {
  a: {
    stars_count_company: presence,
    stars_count_profession: presence,
    stars_count_learning: presence,
    stars_count_fun: presence
  },
  b: {
    school_stars_count_profession: presence,
    school_stars_count_learning: presence,
    school_stars_count_fun: presence
  }
}

const step5Validations = {
  a: {
    comment_on_company: presence,
    comment_on_profession: presence
  },
  b: {
    comment_on_profession: presence
  }
}

const step6Validations = {
  a: {
    taken_on_status: presence,
    recommend_company: presence,
    recommend_profession: presence
  },
  b: {
    school_recommend_profession: presence
  }
}

const step7Validations = {
  email
}

export default {
  'klassische-duale-berufsausbildung': {
    '#schritt-1': {},
    '#schritt-2a': step2Validations.a,
    '#schritt-3': step3Validations,
    '#schritt-4a': step4Validations.a,
    '#schritt-5a': step5Validations.a,
    '#schritt-6a': step6Validations.a,
    '#schritt-7': step7Validations
  },
  'betriebsinterne-ausbildung': {
    '#schritt-1': {},
    '#schritt-2a': step2Validations.a,
    '#schritt-3': step3Validations,
    '#schritt-4a': step4Validations.a,
    '#schritt-5a': step5Validations.a,
    '#schritt-6a': step6Validations.a,
    '#schritt-7': step7Validations
  },
  'duales-studium': {
    '#schritt-1': {},
    '#schritt-2b': step2Validations.b,
    '#schritt-3': step3Validations,
    '#schritt-4a': step4Validations.a,
    '#schritt-5a': step5Validations.a,
    '#schritt-6a': step6Validations.a,
    '#schritt-7': step7Validations
  },
  'schulische-ausbildung': {
    '#schritt-1': {},
    '#schritt-2c': step2Validations.c,
    '#schritt-3': step3Validations,
    '#schritt-4b': step4Validations.b,
    '#schritt-5b': step5Validations.b,
    '#schritt-6b': step6Validations.b,
    '#schritt-7': step7Validations
  },
  weiterbildung: {
    '#schritt-1': {},
    '#schritt-2a': step2Validations.a,
    '#schritt-3': step3Validations,
    '#schritt-4a': step4Validations.a,
    '#schritt-5a': step5Validations.a,
    '#schritt-6a': step6Validations.a,
    '#schritt-7': step7Validations
  },
  abiturientenprogramm: {
    '#schritt-1': {},
    '#schritt-2a': step2Validations.a,
    '#schritt-3': step3Validations,
    '#schritt-4a': step4Validations.a,
    '#schritt-5a': step5Validations.a,
    '#schritt-6a': step6Validations.a,
    '#schritt-7': step7Validations
  }
}
