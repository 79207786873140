/* global $ window */
import cookie from 'js-cookie'

const LOGIN_LINK = '.js-open_login_modal'

export default class ClickAfterLogin {
  constructor(dataAttribute, target) {
    this.$apprenticeLoginLink = $(LOGIN_LINK)
    this.addEventListener(dataAttribute, target)
    this.clickAfterLogin(dataAttribute, target)
  }

  addEventListener(dataAttribute, target) {
    target.on('click', () => {
      this.$apprenticeLoginLink.attr('data-trigger-popup', dataAttribute)
      this.$apprenticeLoginLink.trigger('afterClick')
    })
  }

  clickAfterLogin(dataAttribute, target) {
    const popUpValue = cookie.get('triggerPopUp')
    if (popUpValue === dataAttribute) {
      cookie.remove('triggerPopUp')
      window.location = target.attr('href')
    }
  }
}
