import Page from '../page'
import MainSearchWithResultsWidget from '../../../../widgets/main_search_with_results/script'

export default class MainSearchPage extends Page {
  constructor() {
    super()

    new MainSearchWithResultsWidget(true, 20)
  }
}
