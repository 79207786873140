import Swiper from 'swiper'
import { Navigation } from 'swiper/modules'

export default class NearbyCorporationsSliderWidget {
  constructor() {
    this.sliderContainer = document.querySelector('.js-nearby-corporations')

    this.initObserver()
    this.loadSlider(this.sliderContainer.dataset)
  }

  initObserver() {
    this.observer = new MutationObserver(() => {
      this.sliderLoadedCallback()
    })

    this.observer.observe(this.sliderContainer, {
      childList: true,
      subtree: true,
      threshold: 'childList', // Only observe changes to immediate children
    })
  }

  loadSlider(dataset) {
    const params = this.extractParams(dataset)
    const searchParams = new URLSearchParams()

    Object.entries(params).forEach((pair) => {
      const key = pair[0]
      const value = pair[1]

      if (value !== undefined && value !== null) {
        searchParams.append(key, value)
      }
    })

    fetch(`${dataset.carouselPath}?${searchParams}`)
      .then((response) => {
        if (response.ok) {
          return response.text()
        }
        throw new Error(`${response.status} ${response.statusText}`)
      })
      .then((html) => {
        this.sliderContainer.innerHTML = html
      })
      .catch((error) => {
        console.log(error)
      })
  }

  sliderLoadedCallback() {
    const carouselSelector = '.js-nearby-corporation-carousel'
    const sliderContainer = document.querySelector(carouselSelector)
    if (sliderContainer !== null) {
      const swiper = new Swiper({
        modules: [Navigation],

        slidesPerView: 'auto',
        centeredSlides: true,
        loop: true,
        spaceBetween: 30,
        watchSlidesProgress: true,

        breakpoints: {
          1800: {
            centeredSlides: false,
          },
          1200: {
            centeredSlides: false,
          },
          768: {
            centeredSlides: true,
          },
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
      })

      swiper.init(carouselSelector)
      this.observer.disconnect()
    }
  }

  extractParams({
    searchCityName,
    searchCityPublicId,
    searchStateSlug,
    searchLatitude,
    searchLongitude,
    searchCityFilterActive,
    searchListInCity,
  }) {
    return {
      city_name: searchCityName,
      state_slug: searchStateSlug,
      city_public_id: searchCityPublicId,
      latitude: searchLatitude,
      longitude: searchLongitude,
      city_filter_active: searchCityFilterActive,
      list_in_city: searchListInCity,
    }
  }
}
