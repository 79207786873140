import Events from '../../javascript/src/config/events'
import AbPubSub from '../../javascript/src/lib/ab_pubsub'

export default class SearchImprovementSuggestions {
  constructor() {
    this.initEventHandler()
  }

  initEventHandler() {
    const delegateElem = document.querySelector('.js-search-radius-delegate')

    if (delegateElem) {
      delegateElem.addEventListener('click', this.handle.bind(this))
    }
  }

  handle(event) {
    const targetElem = event.target
    const abPubSub = AbPubSub.getInstance()

    if (targetElem && targetElem.dataset.radius) {
      const radius = parseInt(targetElem.dataset.radius, 10)
      abPubSub.emit(Events.ON_SEARCH_RADIUS_CHANGE, { radius })
    }
  }
}
