import ActiveSourcing from '../pages/frontend/active_sourcing/active_sourcing'
// frontend/ratgeber
import AdviserPage from '../pages/frontend/adviser/adviser'
import CityPage from '../pages/frontend/cities/city'
import CityProfessionPage from '../pages/frontend/cities/cityProfession'
import CmsPage from '../pages/frontend/cms_page'
// branchen
import IndustriesShowPage from '../pages/frontend/industries/show'
import DaExplanation from '../pages/frontend/landingpages/da_explanation'
import MainSearch from '../pages/frontend/main_search'
import ProfessionQuizLastPage from '../pages/frontend/profession_quiz/last_step'
import ProfessionQuizResults from '../pages/frontend/profession_quiz/results'
import ProfessionQuizStepPage from '../pages/frontend/profession_quiz/steps'
import Reviews from '../pages/frontend/reviews/review'
// frontend/landingpages
import SeoKeywordPage from '../pages/frontend/seo_keyword'
import StatePage from '../pages/frontend/states/state'

import Page from '../pages/page'
import PasswordResetPage from '../pages/dashboard/password_reset'

export default [
  // frontend
  ['staedte/alle', Page],
  ['staedte/alle/(.*)', Page],
  ['staedte/(.*)/alle-berufe', Page],
  ['staedte/([^/]+)/.*', CityProfessionPage],
  ['staedte/(.*)', CityPage],

  //
  ['bundeslaender', Page],
  ['bundeslaender/(.*)', StatePage],
  ['suche', MainSearch],
  ['bewerten', Reviews],
  ['berufscheck', ProfessionQuizStepPage],
  ['berufscheck/fragen/12', ProfessionQuizLastPage],
  ['berufscheck/fragen/ergebnis', ProfessionQuizResults],
  ['berufscheck/fragen/(.*)', ProfessionQuizStepPage],

  // Branchen
  ['branchen', Page],
  ['branchen/(.*)', IndustriesShowPage],

  // ---
  // frontend/ratgeber
  ['ratgeber', Page],
  ['ratgeber/(.*)', AdviserPage],

  // ---
  // frontend/landingpages
  ['lass-dich-finden', ActiveSourcing],
  ['lehrer', Page],
  ['kontakt', Page],
  ['impressum', CmsPage],
  ['datenschutz', CmsPage],
  ['agb', CmsPage],
  ['nutzungsbedingungen', CmsPage],
  ['bildquellen', CmsPage],
  ['bewerben-in-drei-schritten', DaExplanation],

  // CMS Preview
  ['cms-preview', CmsPage],

  ['dashboard/passwort-vergessen/setzen', PasswordResetPage],

  // Assume that every 'single-part' path not otherwise mentioned is a seo keyword
  ['([a-z0-9-]*)', SeoKeywordPage],

  // catchall script for routes, with no special script
  ['(.*)', Page],
]
