/* global window */
export default class Router {
  constructor(routes) {
    this.routes = routes
    this.handleRoute()
  }

  /**
   * Checks if there's a javascript for the current route, requires the class and
   * instantiates it
   * @private
   */
  handleRoute() {
    let { pathname } = window.location

    // Remove leading and trailing slashes
    pathname = pathname.replace(/^\/+|\/+$/g, '')

    // Go through routes and check which one matches
    for (let i = 0; i < this.routes.length; i += 1) {
      const [route, PageClass] = this.routes[i]

      const regexp = new RegExp(`^${route}$`, 'i')

      if (route === true || regexp.test(pathname)) {
        this.currentPage = new PageClass()

        break
      }
    }
  }
}
