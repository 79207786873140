/* global $ */
import Events from '../../../javascript/src/config/events'
import AbPubSub from '../../../javascript/src/lib/ab_pubsub'

export default class SingleSelect {
  get selector() {
    return '.js-selectize'
  }

  constructor(cfg = {}) {
    this.pubSub = AbPubSub.getInstance()

    $(this.selector).each((index, element) => {
      const config = { maxItems: 1, ...cfg }

      const onBlurConfig = GetSelectizeOnBlurConfig(index)
      element.classList.add(onBlurConfig.findAs)
      const control = $(element).selectize(Object.assign(onBlurConfig, config))

      control.on('change', e => {
        this.pubSub.emit(Events.ON_SINGLE_SELECT_CHANGE, {
          event: e,
          id: element.id,
          currentText: element.textContent,
          previousValue: $(element).data('previousValue'),
          previousText: $(element).data('previousText')
        })
      })
    })
  }
}
