/* global */

import AbPubSub from './ab_pubsub'
import Events from '../config/events'
import UtilityHelper from './utility_helper'

class TextEllipsisObject {
  constructor(node, data = {}) {
    this.node = node

    try {
      this.text = data.text

      if (data.lines) {
        this.calculationMode = 'lines'
        this.lines = data.lines
      } else {
        this.calculationMode = 'auto'
      }
    } catch (e) {
      console.error('Node has no original text for ellipsis.', node)
    }
  }
}

export default class TextEllipsis {
  get textEllipsisSelector() {
    return '.js-text-ellipsis'
  }

  /* DO NOT CALL DIRECTLY! PSEUDO SINGLETON! */
  constructor() {
    this.textEllipsisObjects = []
    this.pubSub = AbPubSub.getInstance()
    this.create = this.create.bind(this)
    this.calculate = this.calculate.bind(this)
    this.calculateAll = this.calculateAll.bind(this)
  }

  /* ALWAYS USE THIS! */
  static getInstance() {
    if (!this.instance) {
      this.instance = new TextEllipsis()
    }
    return this.instance
  }

  create() {
    const textEllipsisNodes = document.querySelectorAll(this.textEllipsisSelector)
    textEllipsisNodes.forEach((node) => {
      const textEllipsisObject = new TextEllipsisObject(node, node.dataset)
      if (!this.textEllipsisObjects.includes(textEllipsisObject)) {
        this.textEllipsisObjects.push(textEllipsisObject)
      }
    })
    this.pubSub.subscribe(Events.ON_AB_RESIZE, UtilityHelper.debounce(this.calculateAll, 100))
    this.calculateAll()
  }

  calculateAll() {
    this.textEllipsisObjects.forEach((textEllipsisObject) => {
      // recalculate every ellipsis
      this.calculate(textEllipsisObject)
    })
  }

  calculate(textEllipsisObject) {
    const node = textEllipsisObject.node
    const $node = $(node)
    const calculationMode = textEllipsisObject.calculationMode
    const lineHeight = parseInt($node.css('lineHeight').replace('px', ''), 10)
    let desiredHeight = null

    // set text to original text for resize events
    $node.text(textEllipsisObject.text)

    if (calculationMode === 'lines') {
      desiredHeight = textEllipsisObject.lines * lineHeight + 2 // +2 calculation tolerance
    } else if (calculationMode === 'auto') {
      desiredHeight = node.getBoundingClientRect().height + 2 // +2 calculation tolerance
    }

    while (node.scrollHeight > desiredHeight) {
      const regex = /\W*\s([^\s…])+\W*$/

      if (!$node.text().match(regex)) break

      $node.text((_i, text) => text.replace(regex, ' …'))
    }
  }
}
