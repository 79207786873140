import Page from '../../page'

import { AbstractScrollableAnchorBoxWidget } from '../../../../../widgets/scrollable_anchor_box/main/script'
import GeneralContactFormWidget from '../../../../../widgets/general_contact_form/script'
import SocialMediaElementWidget from '../../../../../widgets/social_media_element/script'
import FadingTables from '../cms/fading_tables'
import VacancyCountBreakerWidget from '../../../../../widgets/vacancy_count_breaker/cms/script'
import ArticleGrid from '../../../../../widgets/article_grid/script'

export default class AdviserPage extends Page {
  constructor() {
    super()
    // if we render quicklinks we do not have a anchorbox
    if (!document.getElementById('js-quicklinks')) new AbstractScrollableAnchorBoxWidget()
    new GeneralContactFormWidget()
    new FadingTables()
    new VacancyCountBreakerWidget()
    new SocialMediaElementWidget()
    new ArticleGrid()
  }
}
