/* global $ */

import Cleave from 'cleave.js'
import randomText from './random_text'
import Events from '../config/events'

export default class FormattedInput {
  constructor(pubSub, cfg = {}) {
    const internalCfg = Object.assign(
      {
        selector: '.js-formatted-data',
      },
      cfg
    )
    this.pubSub = pubSub
    this.selector = internalCfg.selector
    this.$instances = $(this.selector)

    if (this.$instances.length) {
      this.init()
      this.pubSub.subscribe(Events.ON_APPEND_DYNAMIC_INPUT, this.reinit.bind(this))
    }
  }

  reinit() {
    this.$instances = $(this.selector)
    this.init()
  }

  init() {
    this.$instances.each((idx, elem) => {
      const cfg = $(elem).data('format')

      if (!cfg) {
        return
      }

      const id = `js-formatted-data--${randomText()}`
      $(elem).addClass(id)

      new Cleave(`.${id}`, cfg)
    })
  }
}
