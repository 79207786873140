import Page from '../../page'
import ProfessionQuizStepWidget from '../../../../../widgets/profession_quiz/step/script'
import ProfessionQuizCard from '../../../../../widgets/profession_quiz/card/script'

export default class ProfessionQuizStepPage extends Page {
  constructor() {
    super()
    this.quizStepWidget = new ProfessionQuizStepWidget()
    this.quizCardWidget = new ProfessionQuizCard()
  }
}
