/* global $ */
import Events from '../../../javascript/src/config/events.js'
import AbPubSub from '../../../javascript/src/lib/ab_pubsub'

export default class MultiSelectMultiline {
  get selector() {
    return '.js-multi-selectize-multiline'
  }

  get triggerSelector() {
    return 'js-multi-selectize-multiline--dynamic'
  }

  constructor(cfg = {}) {
    this.pubSub = AbPubSub.getInstance()
    this.pubSub.subscribe(Events.ON_SINGLE_SELECT_CHANGE, this.onSingleSelectChange.bind(this))

    const $selectizeSelector = $(this.selector)

    $selectizeSelector.each((_index, elem) => {
      let config = Object.assign({}, cfg)
      const $currentSelectize = $(elem)
      const isDynamic = $currentSelectize.hasClass(this.triggerSelector)
      const maxItems = $currentSelectize.data('maxItems')

      let individualConf = {
        sortField: [
          { field: 'optgroup', direction: 'asc' },
          { field: 'text', direction: 'asc' },
        ],
      }

      if (isDynamic) {
        const model = $currentSelectize.data('dynamicModel')
        individualConf = this.initAjax(model)
      }

      if (maxItems) {
        individualConf.maxItems = maxItems
      }

      config = Object.assign(individualConf, config)
      $currentSelectize.selectize(config)
    })
  }

  /*
   * React to change in a different single select(ize) field
   */
  onSingleSelectChange(e) {
    // Get important info from pubsub event
    const targetId = e.id
    const targetText = e.currentText
    const { previousValue, previousText } = e

    // Find the multiline field that was configured to handle the change event
    $(this.selector).each((_index, elem) => {
      // Extract its `react-to` data value
      const reactToId = $(elem).data('on-single-select-change')

      // Check if it matches the target id
      // Note: this `endsWith` stuff seems hacky but matches the haml code nicely
      if (reactToId && targetId.endsWith(reactToId)) {
        const $currentSelectize = $(elem)[0].selectize

        // Find the option to remove by finding the correct text
        //
        // Note: For some reason the `value`s don't match between
        //       the two selectize fields, so we cannot compare them with that
        const { options } = $currentSelectize
        const optionKeys = Object.keys(options)
        const optionToRemove = optionKeys.find((i) => options[i].text === targetText)

        // Remove the selected option and (re-)add the deselected option
        $currentSelectize.removeOption(optionToRemove)
        $currentSelectize.addOption({
          text: previousText,
          value: previousValue,
        })
      }
    })
  }

  initAjax(model) {
    return {
      load: (query, callback) => {
        if (!query.length) {
          return callback()
        }
        this.loadData(query, model).then((data) => {
          callback(data)
        })

        // es lint
        return true
      },
    }
  }

  loadData(query, model) {
    return $.ajax({
      url: `/ajax/selectize_${model}/`,
      type: 'GET',
      dataType: 'json',
      data: {
        name: query,
      },
    })
  }
}
