import DOMPurify from 'dompurify'

const NAV_ELEM_SELECTOR = 'js-article-grid-nav-elem'
const GRID_ELEM_SELECTOR = 'js-article-grid-articles'
const ACTIVE_NAV_ELEM_CLASS = 'article-grid__nav-elem--active'

export default class ArticleGrid {
  constructor() {
    this.navElems = Array.from(document.getElementsByClassName(NAV_ELEM_SELECTOR))
    this.gridContainer = document.getElementById(GRID_ELEM_SELECTOR)
    this.initListener()
  }

  initListener() {
    this.navElems.forEach((element) => {
      element.addEventListener('click', (e) => this.handleActiveTabChhange(e.target))
      element.addEventListener('keydown', (e) => {
        if (e.key === 'Enter' || e.key === ' ') {
          e.preventDefault()
          this.handleActiveTabChhange(e.target)
        }
      })
    })
  }

  handleActiveTabChhange(targetElem) {
    this.navElems.forEach((element) => {
      element.classList.remove(ACTIVE_NAV_ELEM_CLASS)
      element.setAttribute('tabIndex', 0)
    })
    targetElem.classList.add(ACTIVE_NAV_ELEM_CLASS)
    targetElem.setAttribute('tabIndex', -1)
    this.gridContainer.innerHTML = this.generateGridHTML(targetElem)
  }

  generateGridHTML(categoryElem) {
    let html = ''

    JSON.parse(categoryElem.dataset.articles).forEach((article) => {
      html += `
          <a href="${article.link}" class="article-grid__article">
            <img src="${article.image_url}" alt="${article.image_alternative_text || ''}" />
            ${article.text ? `<div class="article-grid__text">${article.text}</div>` : ''}
          </a>
        `
    })

    return DOMPurify.sanitize(html)
  }
}
