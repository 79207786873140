/* global */
import Page from '../../page'

import MainSearchWithResultsWidget from '../../../../../widgets/main_search_with_results/script'

export default class IndustriesShowPage extends Page {
  constructor() {
    super()
    this.mainSearchWithResultsWidget = new MainSearchWithResultsWidget(false, 24)
  }
}
