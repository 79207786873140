/* global document */
import ViewportHelper from '../../../lib/viewport_helper'

export default class FadingTables {
  get tableContainerSelector() {
    return '.ab-cms-container--table'
  }

  get containerSelector() {
    return '.ab-container'
  }

  get fadedSelector() {
    return '.faded'
  }

  constructor() {
    this.viewportHelper = ViewportHelper.getInstance()

    if (this.viewportHelper.isMobile()) {
      this.initBehavior()
    }
  }

  removeFadeOnScrollEnd(tableContainer, tableContainerParent) {
    if (tableContainer.scrollLeft >= tableContainer.scrollWidth
        - tableContainer.clientWidth) {
      this.removeFading(tableContainer, tableContainerParent)
    } else {
      this.applyFading(tableContainer, tableContainerParent)
    }
  }

  removeFading(tableContainer, tableContainerParent) {
    tableContainer.classList.remove('no-border')
    tableContainerParent.classList.remove('faded')
  }

  applyFading(tableContainer, tableContainerParent) {
    if (tableContainerParent.querySelector('.fade')) {
      tableContainerParent.classList.add('faded')
      tableContainer.classList.add('no-border')
    } else {
      this.createFadingElement(tableContainerParent)
      tableContainerParent.classList.add('faded')
      tableContainer.classList.add('no-border')
    }
  }

  createFadingElement(tableContainerParent) {
    const elem = document.createElement('div')

    elem.classList.add('fade')
    tableContainerParent.appendChild(elem)
  }

  initBehavior() {
    const tableContainers = document.querySelectorAll(
      this.tableContainerSelector
    )

    Array.from(tableContainers).forEach(tableContainer => {
      if (tableContainer.scrollWidth > tableContainer.clientWidth) {
        const tableContainerParent = tableContainer.parentElement

        this.applyFading(tableContainer, tableContainerParent)
        tableContainer.addEventListener('scroll', () => {
          this.removeFadeOnScrollEnd(tableContainer, tableContainerParent)
        })
      }
    })
  }
}
