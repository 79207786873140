/* global $ */
import Page from '../../page'
import ClickAfterLogin from '../../../lib/click_after_login'
import DaExplanationJobPostingsWidget from '../../../../../widgets/direct_application/frontend/explanation/job_postings/script'

const FILL_PROFILE_BUTTON_SELECTOR = '.js-click-after-login'

export default class DaExplanation extends Page {
  constructor() {
    super()
    new ClickAfterLogin('fillProfileIntro', $(FILL_PROFILE_BUTTON_SELECTOR))
    new DaExplanationJobPostingsWidget()
  }
}
