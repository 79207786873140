export default {
  'klassische-duale-berufsausbildung': [
    '#schritt-1',
    '#schritt-2a',
    '#schritt-3',
    '#schritt-4a',
    '#schritt-5a',
    '#schritt-6a',
    '#schritt-7',
    '#loading'
  ],
  'betriebsinterne-ausbildung': [
    '#schritt-1',
    '#schritt-2a',
    '#schritt-3',
    '#schritt-4a',
    '#schritt-5a',
    '#schritt-6a',
    '#schritt-7',
    '#loading'
  ],
  'duales-studium': [
    '#schritt-1',
    '#schritt-2b',
    '#schritt-3',
    '#schritt-4a',
    '#schritt-5a',
    '#schritt-6a',
    '#schritt-7',
    '#loading'
  ],
  'schulische-ausbildung': [
    '#schritt-1',
    '#schritt-2c',
    '#schritt-3',
    '#schritt-4b',
    '#schritt-5b',
    '#schritt-6b',
    '#schritt-7',
    '#loading'
  ],
  weiterbildung: [
    '#schritt-1',
    '#schritt-2a',
    '#schritt-3',
    '#schritt-4a',
    '#schritt-5a',
    '#schritt-6a',
    '#schritt-7',
    '#loading'
  ],
  abiturientenprogramm: [
    '#schritt-1',
    '#schritt-2a',
    '#schritt-3',
    '#schritt-4a',
    '#schritt-5a',
    '#schritt-6a',
    '#schritt-7',
    '#loading'
  ]
}
