import routes from './src/config/routes.js'
import Router from './src/lib/router'

import 'slick-carousel/slick/slick.css'
import 'tooltipster/dist/css/tooltipster.bundle.min.css'
import 'tooltipster/dist/css/plugins/tooltipster/sideTip/themes/tooltipster-sideTip-shadow.min.css'

import './vendor/vendor.js'
import $ from './jquery.js'

class Ausbildung {
  constructor() {
    this.router = new Router(routes)
  }
}

$(() => {
  window.ausbildung = new Ausbildung()
})
