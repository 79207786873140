import Page from '../page'
import FadingTables from './cms/fading_tables'
import AbstractScrollableAnchorBox from '../../../../widgets/scrollable_anchor_box/main/abstract_scrollable_anchor_box_widget'
import GeneralContactFormWidget from '../../../../widgets/general_contact_form/script'
import SocialMediaElementWidget from '../../../../widgets/social_media_element/script'
import VacancyCountBreakerWidget from '../../../../widgets/vacancy_count_breaker/cms/script'

export default class CmsPage extends Page {
  constructor() {
    super()

    new AbstractScrollableAnchorBox()
    new GeneralContactFormWidget()
    new FadingTables()
    new VacancyCountBreakerWidget()

    // We use this to hide the cookiebot banner on certain pages
    this.cookiebotMutationObserver = ''

    const url = window.location.href
    const isPrivacyPage = url.indexOf('datenschutz') > -1
    // Check whether or not we're on the imprint- or privacy page
    if (url.indexOf('impressum') > -1 || isPrivacyPage) {
      this.observeCookieDialog()
    }

    new SocialMediaElementWidget(isPrivacyPage)
  }

  observeCookieDialog() {
    this.cookiebotMutationObserver = new MutationObserver(this.hideCookieBanner.bind(this))

    const config = {
      attributes: true,
      childList: true,
      subtree: true,
    }

    // Start observation of HTML body
    this.cookiebotMutationObserver.observe(document.body, config)

    // We proactively disconnect our mutation observer after 5 seconds here
    // in order to let the „change your settings“ cookiebot feature
    // do its work correctly. See also: reasoning in hideCookieBanner() for
    // why we cannot do this more intelligently
    setTimeout(() => {
      this.cookiebotMutationObserver.disconnect()
    }, 5000)
  }

  hideCookieBanner() {
    const dialog = document.querySelector('#CybotCookiebotDialog')
    const dialogUnderlay = document.querySelector('#CybotCookiebotDialogBodyUnderlay')

    if (dialog && dialogUnderlay && (dialog.style.display !== 'none' || dialogUnderlay.style.display !== 'none')) {
      // Hide cookiebot dialog and underlay whenever they seem to be visible
      document.body.style.overflow = 'visible'
      dialog.style.display = 'none'
      dialogUnderlay.style.display = 'none'

      // I would really looove to disconnect this mutation observer here,
      // but the underlay’s style is somehow reset again after a while.
      // This makes finding the correct condition for when to stop observing
      // almost impossible.
      // this.cookiebotMutationObserver.disconnect()
    }
  }
}
