/* global $, window */
const SELECTIZE_CITIES_SELECTOR = '.js-selectize-cities'
const SELECTIZE_PROFESSIONS_SELECTOR = '.js-selectize-professions'
const SELECTIZE_CORPORATIONS_SELECTOR = '.js-selectize-corporations'
const SELECTIZE_UNIVERSITY_SELECTOR = '.js-selectize-universities'

export default class AjaxSingleSelectInputWidget {
  constructor() {
    this.initialize()
  }

  initialize() {
    $(SELECTIZE_CITIES_SELECTOR).selectize(
      Object.assign(this.initSelectizeForAjax('cities'), {
        onBlur: () => {
          const cities = $(SELECTIZE_CITIES_SELECTOR)
          const citiesSelectize = cities[0].selectize
          const searchResults = citiesSelectize.currentResults
          const hasSearchResults = searchResults.total > 0
          // Since we don't get an `itemSelected` event or `onItemAdd` before
          // `onBlur` we have to infer whether the user clicked an item
          // or simply clicked away. After some experimentation this was the
          // most reliable indicator.
          const isPossibleDirectItemClick = searchResults.query === ''

          if (hasSearchResults && isPossibleDirectItemClick) {
            // Do nothing, the user has clicked on a search result and this
            // is handled somewhere else
          } else if (hasSearchResults) {
            // The user clicked somewhere else, select the topmost result for her
            const id = searchResults.items[0].id
            citiesSelectize.addItem(id)
          } else {
            // Do nothing, the user clicked away with no search results
          }
        }
      })
    )
    $(SELECTIZE_PROFESSIONS_SELECTOR).selectize(this.initSelectizeForAjax('professions'))
    $(SELECTIZE_CORPORATIONS_SELECTOR).selectize(this.initSelectizeForAjax('corporations'))
    $(SELECTIZE_UNIVERSITY_SELECTOR).selectize(this.initSelectizeForAjax('universities'))
  }

  initSelectizeForAjax(type) {
    return {
      maxItems: 1,
      sortField: 'text',
      load: (query, callback) => {
        if (!query.length) {
          return callback()
        }

        $.ajax({
          url: `//${window.location.host}/ajax/selectize_${type}/`,
          type: 'GET',
          dataType: 'json',
          data: {
            name: query
          },
          error: () => {
            callback()
          },
          success: res => {
            callback(res)
          }
        })
      }
    }
  }
}
