/* global $ */
export default class AbstractContactForm {
  constructor(selector) {
    this.selector = selector
    this.$form = $(`.${this.selector}-box__form`)
    this.$openLink = $('#js-contact-form-open-form')
    this.$closeLink = $('#js-contact-form-close-form')
    this.$nameField = $('#js-contact-form-name')
    //
    this.addEventHandlers(this.selector)
    this.registerOpenCloseForm()
  }

  addEventHandlers(selector) {
    const CONTACT_FORM_SELECTOR = `.${selector}-box__contact-form`
    const SUCCESS_VIEW_SELECTOR = `.${selector}-box__success-view`
    const errorClass = 'field_with_errors'
    const disabledButtonClass = 'btn-filled btn--disabled'
    const $submitButton = $('.js-contact-form-submit-button')

    this.$form
      .on('ajax:error', (_event, xhr) => {
        const errors = xhr.responseJSON.frontend_errors

        Object.keys(errors).map(key => {
          const $field = this.$form.find(`[name='contact_author[${key}]']`)
          $field.parent().addClass(errorClass)
          $field.after(
            `<div class="form-error-message contact-form-box__form-error">${errors[key]}</div>`
          )
        })

        $submitButton.prop('disabled', false).removeClass(disabledButtonClass)
      })
      .on('ajax:send', () => {
        const $input = this.$form.find('.input-field')
        $input.removeClass(errorClass)
        $input.find('.form-error-message').remove()

        $submitButton.prop('disabled', true).addClass(disabledButtonClass)
      })
      .on('ajax:success', () => {
        $(CONTACT_FORM_SELECTOR).css('display', 'none')
        $(SUCCESS_VIEW_SELECTOR).css('display', 'block')
        $(this.formSelector).replaceWith('<h2>Deine Nachricht wurde erfolgreich gesendet</h2>')
      })
  }

  registerOpenCloseForm() {
    this.$openLink.on('click', e => {
      e.preventDefault()
      this.showForm()
    })

    this.$closeLink.on('click', () => {
      this.hideForm()
    })
  }

  hideForm() {
    this.$openLink.show()
    this.$form.hide()
  }

  showForm() {
    this.$openLink.hide()
    this.$form.show()
    $(this.$nameField).focus()
  }
}
