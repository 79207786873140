/* global $ window */

import Events from '../../../javascript/src/config/events'
import AjaxSpinnerWidget from '../../ajax_spinner/script'
import JobPostingBookmarkWidget from '../../job_posting/bookmark/script'

const NEARBY_PROF_TMP_SELECTOR = '#js-nearby-professions-template'
const EXPANDED_RADIUS = 50
const SPINNER_SELECTOR = '.js-spinner-nearby-professions'
const JOBPOSTINGS_CONTAINER = '.nearby-professions__content'
const FORM_SELECTOR = '.js-main-search-form'
const SEARCH_URL = '/ajax/main_search/'

export default class NearbyProfessions {
  constructor(pubSub, insertSelector = '.js-search-improvement-suggestions-here') {
    this.pubSub = pubSub
    this.$template = $(NEARBY_PROF_TMP_SELECTOR).abLoadHTMLTemplate()
    this.$insertSelector = $(insertSelector)
    this.$inserted = null
    this.ajaxSpinner = new AjaxSpinnerWidget(SPINNER_SELECTOR)

    this.registerListener()
  }

  registerListener() {
    this.pubSub.subscribe(Events.ON_MAIN_SEARCH_RESULTS_LOADED, this.handleChange.bind(this))
    this.pubSub.subscribe(Events.ON_SEARCH_RADIUS_CHANGE, this.handleChange.bind(this))
  }

  handleChange(opts) {
    if (opts.jobPostingsLength && opts.jobPostingsLength < 5) {
      this.extractPids()
      this.hydrate()
    } else {
      this.clearExpandedResults()
    }
  }

  clearExpandedResults() {
    if (this.$inserted) {
      this.$inserted.remove()
      this.$inserted = null
    }
  }

  extractPids() {
    const jobPostingIds = []

    $('.search-result__wrapper .js-job-posting-card').each(function mapPids() {
      jobPostingIds.push($(this).data('public-id'))
    })

    this.jobPostingIds = jobPostingIds
  }

  hydrate() {
    if (!this.$inserted) {
      this.$inserted = this.$template.insertBefore(this.$insertSelector)
    }

    this.ajaxSpinner.showSpinner()
    const doneCallback = this.onSearchDone.bind(this)

    $.ajax({
      url: this.getSearchUrl(),
    }).done((result) => {
      doneCallback(result)
    })
  }

  onSearchDone(page) {
    this.pageobj = $(page)
    this.extendedRadiusBookmarkWidget = new JobPostingBookmarkWidget('js-nearby-professions')

    this.jobPostings = this.pageobj.filter('.js-job-postings').children()

    this.ajaxSpinner.hideSpinner()
    $(JOBPOSTINGS_CONTAINER).empty().append(this.jobPostings)
    this.pubSub.emit(Events.ON_RELOAD_LOGIN_LINKS)
    this.pubSub.emit(Events.ON_JOB_POSTINGS_LOADED, {
      parentSelector: '.nearby-professions',
      selector: '.js-bookmark-job-posting',
      prefix: 'ASU_',
    })
    if (this.jobPostings.length == 0) {
      $('.nearby-professions').addClass('js-hidden')
    } else if ($('.nearby-professions').hasClass('js-hidden')) {
      $('.nearby-professions').removeClass('js-hidden')
    }
  }

  getSearchUrl() {
    const searchParams = this.getSearchParams()

    const radius = parseInt(searchParams.get('form_main_search[radius]'), 10) || 0
    searchParams.set('form_main_search[radius]', radius + EXPANDED_RADIUS)
    searchParams.set('form_main_search[size]', 5)
    searchParams.set('form_main_search[breaker_tile]', false)

    this.jobPostingIds.forEach((id) => {
      searchParams.append('form_main_search[excluded_job_posting_public_ids][]', id)
    })

    return new URL(`${window.location.origin}${SEARCH_URL}?${searchParams}`)
  }

  getSearchParams() {
    const currentParams = new URLSearchParams(window.location.search)
    if (currentParams.size > 0) {
      return currentParams
    }

    return new URLSearchParams(this.getSerializedForm())
  }

  getSerializedForm() {
    return $(FORM_SELECTOR).serialize()
  }
}
