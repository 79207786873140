/* global $ */

const DROPDOWN_SELECTOR = '.scrollable-anchor-dropdown'

export default class ScrollableAnchorDropdown {
  constructor(anchorBox, listContent) {
    this.$anchorBox = anchorBox
    this.$listContent = listContent
    this.openClass = `${DROPDOWN_SELECTOR}--open`.substring(1)
    this.defaultLabel = $(`${DROPDOWN_SELECTOR}--default`).text()
    this.linkToAnchorBox()
    this.initEvents()
    this.reset()
  }

  linkToAnchorBox() {
    this.$instance = $(DROPDOWN_SELECTOR)
    this.$row = this.$instance.find(`${DROPDOWN_SELECTOR}__row`)
    this.$anchorLinks = this.$instance.find('.scrollable-anchor-box__link')
    this.$label = this.$instance.find(`${DROPDOWN_SELECTOR}__active`)
  }

  initEvents() {
    this.$instance.on('click', `${DROPDOWN_SELECTOR}__row`, this.toggleSelectionBox.bind(this))
    this.$anchorLinks.on('click', this.onSelect.bind(this))
  }

  toggleSelectionBox() {
    this.$instance.toggleClass(this.openClass)
  }

  onSelect(event) {
    const $target = $(event.currentTarget)
    this.copyActive($target)
    this.toggleSelectionBox()
  }

  copyActive($target) {
    const $selectedItem = $('<div/>', {
      class: 'base-box__item scrollable-anchor-box__item',
      css: {
        'counter-increment': `scrollable_anchor_dropdown ${this.$anchorLinks.index($target) + 1}`,
      },
      html: `<div class="scrollable-anchor-dropdown__label">${$target.text()}</div>`,
    })

    this.$label.empty().append($selectedItem)
  }

  reset() {
    const $selectedItem = $('<div/>', {
      html: `<div class="scrollable-anchor-dropdown__label">${this.defaultLabel}</div>`,
    })
    this.$label.empty().append($selectedItem)
  }

  hide() {
    this.$row.hide()
  }

  show() {
    this.$row.show()
  }
}
