/* global $ window validate history */

import VALIDATIONS from './validations'
import CHAINS from './chains'
import AjaxSingleSelectInputWidget from '../ajax_single_select_input/script'
import SingleSelectInputWidget from '../single_select_input/script'

const STEPS_SELECTOR = '#steps'
const FORM_NAME = 'review_form'
const NEXT_BUTTON = '.review__question-card--next-step'
const CHECKED_RADIO_BUTTON = 'input[name="review_form[apprenticeship_type]"]:checked'
const EMAIL_FORM = '#review_form_email'
const SUBMIT_BUTTON = '.js-submit'

export default class ReviewsWidget {
  constructor() {
    this.currentStep = 0

    this.setDefaultHash()

    this.checkedRadioButton = $(CHECKED_RADIO_BUTTON)
    this.currentChain = this.checkedRadioButton.val()
    this.registerEventHandler()
    this.registerCustomValidator()

    // $(window).on('beforeunload', (event) => (
    //   'Diese Seite bittet dich zu bestätigen, dass du die Seite verlassen möchtest – ' +
    //   'Daten, die du eingegeben hast, werden verworfen.'
    // ))
  }

  registerCustomValidator() {
    validate.validators.numericalityWithAllowEmptyString = (value, options) => {
      if (value !== '' && (!/^\d+$/.test(value) || value.startsWith('0'))) {
        return options.message
      }
      return undefined
    }
  }

  disableButton(buttonSelector) {
    $(buttonSelector).prop('disabled', true)
    $(buttonSelector).css({ opacity: 0.7, cursor: 'not-allowed' })
  }

  enableButton(buttonSelector) {
    $(buttonSelector).prop('disabled', false)
    $(buttonSelector).css({ opacity: 1, cursor: 'pointer' })
  }

  isEmail(email) {
    const regex = /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/
    return regex.test(email)
  }

  emailWatcher() {
    this.disableButton(SUBMIT_BUTTON)

    $(EMAIL_FORM).on('keyup', () => {
      if (!this.isEmail($(EMAIL_FORM).val())) {
        this.disableButton(SUBMIT_BUTTON)
      } else {
        this.enableButton(SUBMIT_BUTTON)
      }
    })
  }

  isFirstStep() {
    return this.currentStep === 0
  }

  isLastStep() {
    return this.currentStep === this.currentChain.length - 1
  }

  nextStep() {
    this.currentStep++
    this.changeHash()
  }

  setDefaultHash() {
    if (window.location.hash !== '#abschluss') {
      const baseUrl = window.location.href.split('#')[0]
      history.replaceState({}, null, `${baseUrl}#schritt-1`)
    }
  }

  registerEventHandler() {
    this.registerNextButtonHandler()
    $(window).on('popstate', this.handleHashChange.bind(this))
  }

  registerNextButtonHandler() {
    $(NEXT_BUTTON).on('click', this.onNextButtonClick.bind(this))
  }

  onNextButtonClick() {
    if (this.isFirstStep()) {
      this.dispatchFirstPage()
    } else {
      this.dispatchNextStep()
    }
  }

  dispatchFirstPage() {
    this.checkedRadioButton = $(CHECKED_RADIO_BUTTON)
    this.currentChain = this.checkedRadioButton.val()

    const url = $(NEXT_BUTTON).data('url')
    const callback = () => {
      this.registerNextButtonHandler()
      this.nextStep()
      new AjaxSingleSelectInputWidget()
      new SingleSelectInputWidget()
      this.emailWatcher()
    }
    this.loadChainSteps(url, callback)
  }

  dispatchNextStep() {
    const validationErrors = this.validateStep()
    const validationLength = validationErrors.length

    if (validationLength > 0) {
      this.removeErrors()

      for (let i = 0; i < validationLength; i++) {
        this.renderError(validationErrors[i])
      }
    } else {
      this.nextStep()
    }
  }

  loadChainSteps(url, callback) {
    $.ajax({
      url,
      data: {
        type: this.currentChain
      },
      success: data => {
        $('.review').replaceWith(data)
        callback()
      }
    })
  }

  changeHash() {
    history.pushState({}, null, CHAINS[this.currentChain][this.currentStep])
    this.handleHashChange()
  }

  handleHashChange() {
    const currentChain = CHAINS[this.currentChain]
    const stepIndex = currentChain.indexOf(window.location.hash)

    if (stepIndex < currentChain.length) {
      $(STEPS_SELECTOR).children().hide()
      $(currentChain[stepIndex]).show()
      this.currentStep = stepIndex
    }
  }

  // VALIDATIONS
  // TODO: We should write a dedicated js validator
  renderError(errorObj) {
    const fieldNameParts = Object.keys(errorObj)[0].split('.')
    const fieldName = `${fieldNameParts[0]}[${fieldNameParts[1]}]`
    const field = $(`[name="${fieldName}"]`)

    if (field.length) {
      const message = errorObj[Object.keys(errorObj)[0]][0]
      $(field[0])
        .parents('.input-field')
        .wrap('<div class="field_with_errors"></div>')
        .append(`<div class="form-error-message">${message}</div>`)
    }
  }

  removeErrors() {
    const $fieldWithErrors = $('.field_with_errors')
    $fieldWithErrors.find('.form-error-message').remove()
    $fieldWithErrors.find('.input-field').unwrap('.field_with_errors')
  }

  validateStep() {
    const validationsForStep = this.validationsForStep(this.currentChain /* (this.nextStep - 1) */)
    const validateFieldNames = Object.keys(validationsForStep)
    const validationResults = []

    for (let i = 0, len = validateFieldNames.length; i < len; i++) {
      const fieldName = validateFieldNames[i]
      const value = this.getValueForField(`${FORM_NAME}[${fieldName}]`)

      const validationResult = validate(
        this.getValidationObject(fieldName, value),
        this.prefixValidationNames(validationsForStep, fieldName)
      )


      if (validationResult !== undefined) {
        validationResults.push(validationResult)
      }
    }

    return validationResults
  }

  getValidationObject(fieldName, value) {
    const validationObject = {}
    validationObject[FORM_NAME] = {}
    validationObject[FORM_NAME][fieldName] = value
    return validationObject
  }

  prefixValidationNames(validationsForStep, fieldName) {
    const prefixed = {}
    const validateFieldNames = Object.keys(validationsForStep)
    for (let i = 0, len = validateFieldNames.length; i < len; i++) {
      prefixed[`${FORM_NAME}.${fieldName}`] = validationsForStep[fieldName]
    }
    return prefixed
  }

  getValueForField(fieldName) {
    return $(`[name="${fieldName}"]`).val()
  }

  validationsForStep(chain /* , step */) {
    const validations = VALIDATIONS[chain][window.location.hash]

    const validationsForStep = {}
    const keys = Object.keys(validations)

    for (let i = 0, len = keys.length; i < len; i++) {
      validationsForStep[keys[i]] = validations[keys[i]]
    }

    return validationsForStep
  }
}
